var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "progress" }, [
    _c("div", { staticClass: "progress-wrap" }, [
      _c(
        "svg",
        {
          staticClass: "progress-circle",
          attrs: {
            width: "70px",
            height: "70px",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("circle", {
            staticClass: "progress-circle-back",
            attrs: { cx: "35", cy: "35", r: "33" },
          }),
          _vm._v(" "),
          _c("circle", {
            staticClass: "progress-circle-progress",
            attrs: {
              cx: "35",
              cy: "35",
              r: "33",
              "stroke-dasharray": _vm.progress + ", 207.35",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "progress-text" }, [
        _vm._v(
          _vm._s(_vm.percentage == 100 ? "Loading" : _vm.percentage + "%")
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }