var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "guide-tooltip",
      class: {
        "guide-tooltip--pt": _vm.position == "top",
        "guide-tooltip--pb": _vm.position == "bottom",
        "guide-tooltip--al": _vm.arrow == "left",
        "guide-tooltip--ac": _vm.arrow == "center",
        "guide-tooltip--ar": _vm.arrow == "right",
      },
    },
    [
      _c("div", { staticClass: "guide-tooltip__info" }, [
        _c("strong", { staticClass: "guide-tooltip__heading" }, [
          _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "guide-tooltip__message" }, [
          _vm._v("\n      " + _vm._s(_vm.message) + "\n    "),
        ]),
      ]),
      _vm._v(" "),
      _c("button", {
        staticClass: "guide-tooltip__close",
        on: {
          click: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "guide-tooltip__footer" }, [
        _c("div", { staticClass: "guide-tooltip__counter" }, [
          _vm._v(_vm._s(_vm.currentStep) + " / " + _vm._s(_vm.guideSteps)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "guide-tooltip__buttons" }, [
          _vm.currentStep > 1
            ? _c(
                "button",
                {
                  staticClass:
                    "guide-tooltip__button guide-tooltip__button--outline",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_vm._v("\n        Back\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentStep < _vm.guideSteps
            ? _c(
                "button",
                {
                  staticClass:
                    "guide-tooltip__button guide-tooltip__button--blue",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("next")
                    },
                  },
                },
                [_vm._v("\n        Next\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentStep == _vm.guideSteps
            ? _c(
                "button",
                {
                  staticClass:
                    "guide-tooltip__button guide-tooltip__button--blue",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("save")
                    },
                  },
                },
                [_vm._v("\n        Finish\n      ")]
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }