<template>
  <div contenteditable="true" @keydown.enter.prevent @input="update" @paste="paste"></div>
</template>

<script>
export default {
  props: ['content'],
  methods: {
    update(event) {
      this.$emit('update', event.target.textContent);
    },
    paste(event) {
      event.stopPropagation();
      event.preventDefault();
      document.execCommand('insertHTML', false, event.clipboardData.getData('text/plain'));
    },
  },
  mounted() {
    this.$el.innerText = this.content || '';
  },
};
</script>
