var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.supplier.status === 0
      ? _c("div", { staticClass: "supplier__na" }, [
          _c("p", [_vm._v("Page not found")]),
        ])
      : !_vm.userHasPermission
      ? _c("div", { staticClass: "supplier__na" }, [
          _c("p", [
            _vm._v("Sorry, you don't have permission to edit this supplier"),
          ]),
        ])
      : _c("section", { ref: "supplier", staticClass: "supplier" }, [
          _c("div", { staticClass: "supplier__wrap" }, [
            _vm.supplier.category == null
              ? _c("div", { staticClass: "supplier-category" }, [
                  _c("div", { staticClass: "supplier-category__box" }, [
                    _c("strong", { staticClass: "supplier-category__title" }, [
                      _vm._v("What do you produce?"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "supplier-category__text" }, [
                      _vm._v(
                        "\n            Select whether you produce footwear or if you supply components (such as materials) needed by footwear\n            suppliers.\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "supplier-category__buttons" }, [
                      _c(
                        "label",
                        { staticClass: "supplier-category__button" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.supplier.category,
                                expression: "supplier.category",
                              },
                            ],
                            attrs: { type: "radio", value: "0" },
                            domProps: {
                              checked: _vm._q(_vm.supplier.category, "0"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.supplier, "category", "0")
                              },
                            },
                          }),
                          _vm._v("\n              Footwear\n            "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "label",
                        { staticClass: "supplier-category__button" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.supplier.category,
                                expression: "supplier.category",
                              },
                            ],
                            attrs: { type: "radio", value: "1" },
                            domProps: {
                              checked: _vm._q(_vm.supplier.category, "1"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.supplier, "category", "1")
                              },
                            },
                          }),
                          _vm._v("\n              Components\n            "),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "guideItem9",
                staticClass: "guide-item",
                class: {
                  "guide-item--active":
                    _vm.guide &&
                    _vm.supplier.category != null &&
                    _vm.currentGuideStep == 9,
                },
              },
              [
                _c(
                  "header",
                  {
                    ref: "header",
                    staticClass: "supplier__header",
                    class: {
                      "supplier__header--empty": !_vm.coverPhotoData,
                      "guide-item__content": _vm.guide,
                    },
                    style: [
                      _vm.coverPhotoData
                        ? {
                            backgroundImage:
                              'url("' + _vm.coverPhotoData + '")',
                          }
                        : {
                            background:
                              "radial-gradient(ellipse at center, rgba(65,152,208,0.2) 0%,rgba(65,152,208,0.1) 100%)",
                          },
                    ],
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "supplier__file supplier__header-button",
                        on: {
                          click: function ($event) {
                            return _vm.coverPhotoCroppa.chooseFile()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.supplier.coverPhoto
                                ? "Change cover photo"
                                : "Choose cover photo"
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "loader",
                      class: { "is-visible": _vm.coverPhotoLoading },
                    }),
                  ]
                ),
                _vm._v(" "),
                _vm.guide &&
                _vm.supplier.category != null &&
                _vm.currentGuideStep == 9
                  ? _c("TourTooltip", {
                      attrs: {
                        title: "Add Cover Photo",
                        message:
                          "This will be the first impression of your business. Spend some time to select a photo which captures your business in a good way. It will pay off.",
                        currentStep: _vm.currentStep,
                        guideSteps: _vm.guideSteps,
                        position: "bottom",
                        arrow: "center",
                      },
                      on: {
                        close: _vm.guideClose,
                        next: _vm.guideNext,
                        back: _vm.guideBack,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "supplier__container" }, [
              _c("nav", { staticClass: "supplier__nav" }, [
                _c("ul", { ref: "nav", staticClass: "supplier__nav-list" }, [
                  _c("li", { staticClass: "supplier__nav-item" }, [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "scroll-to",
                            rawName: "v-scroll-to",
                            value: { el: "#overview", offset: -115 },
                            expression: "{ el: '#overview', offset: -115 }",
                          },
                        ],
                        staticClass: "supplier__nav-link",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "supplier__nav-icon",
                          domProps: { innerHTML: _vm._s(_vm.svg.overviewIcon) },
                        }),
                        _vm._v("\n                Overview\n              "),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "supplier__nav-item" }, [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "scroll-to",
                            rawName: "v-scroll-to",
                            value: { el: "#production", offset: -90 },
                            expression: "{ el: '#production', offset: -90 }",
                          },
                        ],
                        staticClass: "supplier__nav-link",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "supplier__nav-icon",
                          domProps: {
                            innerHTML: _vm._s(_vm.svg.productionIcon),
                          },
                        }),
                        _vm._v("\n                Production\n              "),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "supplier__nav-item" }, [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "scroll-to",
                            rawName: "v-scroll-to",
                            value: { el: "#info", offset: -90 },
                            expression: "{ el: '#info', offset: -90 }",
                          },
                        ],
                        staticClass: "supplier__nav-link",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "supplier__nav-icon",
                          domProps: { innerHTML: _vm._s(_vm.svg.companyIcon) },
                        }),
                        _vm._v(
                          "\n                Company information\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "supplier__nav-item" }, [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "scroll-to",
                            rawName: "v-scroll-to",
                            value: { el: "#customers", offset: -90 },
                            expression: "{ el: '#customers', offset: -90 }",
                          },
                        ],
                        staticClass: "supplier__nav-link",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "supplier__nav-icon",
                          domProps: {
                            innerHTML: _vm._s(_vm.svg.customersIcon),
                          },
                        }),
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.supplier.category == 0
                                ? "Component suppliers"
                                : "Footwear suppliers"
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "supplier__nav-item" }, [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "scroll-to",
                            rawName: "v-scroll-to",
                            value: { el: "#gallery", offset: -90 },
                            expression: "{ el: '#gallery', offset: -90 }",
                          },
                        ],
                        staticClass: "supplier__nav-link",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "supplier__nav-icon",
                          domProps: { innerHTML: _vm._s(_vm.svg.galleryIcon) },
                        }),
                        _vm._v("\n                Gallery\n              "),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "supplier__info" }, [
                _c("div", { staticClass: "supplier-content" }, [
                  _c(
                    "div",
                    {
                      ref: "guideItem1",
                      staticClass: "guide-item",
                      class: {
                        "guide-item--active":
                          _vm.guide &&
                          _vm.supplier.category != null &&
                          _vm.currentGuideStep == 1,
                      },
                    },
                    [
                      _c("ContentEditable", {
                        staticClass: "supplier__name",
                        class: { "guide-item__content": _vm.guide },
                        attrs: {
                          id: "overview",
                          "data-placeholder": "Company Name",
                          content: _vm.supplier.title,
                          "data-field": "companyName",
                        },
                        on: {
                          update: function ($event) {
                            _vm.supplier.title = $event
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.titleUniqueError
                        ? _c(
                            "div",
                            {
                              class: {
                                supplier__error: true,
                                "guide-item__content": _vm.guide,
                              },
                              attrs: { "data-error": "companyName" },
                            },
                            [
                              _vm._v(
                                "\n                Company with this name already exists\n              "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.guide && _vm.guideIsStarted && _vm.titleError
                        ? _c(
                            "div",
                            {
                              staticClass: "supplier__error",
                              class: { "guide-item__content": _vm.guide },
                              attrs: { "data-error": "companyName" },
                            },
                            [
                              _vm._v(
                                "\n                Company name is required\n              "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.guide &&
                      _vm.supplier.category != null &&
                      _vm.currentGuideStep == 1
                        ? _c("TourTooltip", {
                            attrs: {
                              title: "Enter and Verify Company Name",
                              message:
                                "Your company name will be among the first impressions, it will be visible in the search.",
                              currentStep: _vm.currentStep,
                              guideSteps: _vm.guideSteps,
                              position: "bottom",
                              arrow: "center",
                            },
                            on: { close: _vm.guideClose, next: _vm.guideNext },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.titleError && _vm.validate
                    ? _c(
                        "div",
                        {
                          staticClass: "supplier__error",
                          attrs: { "data-error": "companyName" },
                        },
                        [
                          _vm._v(
                            "\n              Company name is required\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    directives: [
                      {
                        name: "quill",
                        rawName: "v-quill:supplierOverview",
                        value: _vm.editorOptions,
                        expression: "editorOptions",
                        arg: "supplierOverview",
                      },
                    ],
                    staticClass: "supplier__overview supplier-content",
                    on: {
                      ready: function ($event) {
                        return _vm.onEditorReady($event)
                      },
                    },
                    model: {
                      value: _vm.supplier.overview,
                      callback: function ($$v) {
                        _vm.$set(_vm.supplier, "overview", $$v)
                      },
                      expression: "supplier.overview",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "supplier__contacts" },
                    [
                      !_vm.$store.user.chineese
                        ? _c("div", {
                            ref: "supplierMap",
                            staticClass: "supplier__map",
                            class: {
                              "supplier__map--guide": _vm.currentGuideStep == 2,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$store.user.chineese
                        ? _c(
                            "baidu-map",
                            {
                              ref: "supplierMap",
                              staticClass: "supplier__map",
                              class: {
                                "supplier__map--guide":
                                  _vm.currentGuideStep == 2,
                              },
                              attrs: {
                                center: _vm.baiduCenter,
                                "scroll-wheel-zoom": _vm.baidu.scrollWheelZoom,
                                zoom: _vm.baidu.zoom,
                                ak: _vm.baidu.ak,
                                mapStyle: _vm.baidu.mapStyle,
                              },
                              on: {
                                ready: _vm.baiduReady,
                                load: _vm.baiduLoad,
                              },
                            },
                            [
                              _c("bm-navigation", {
                                attrs: { anchor: "BMAP_ANCHOR_TOP_RIGHT" },
                              }),
                              _vm._v(" "),
                              _vm.baiduCoordinates
                                ? _c("bm-marker", {
                                    attrs: {
                                      position: _vm.baiduCoordinates,
                                      icon: _vm.baidu.markerStyle,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "supplier__contacts-info" }, [
                        _c(
                          "div",
                          {
                            ref: "guideItem2",
                            staticClass: "guide-item",
                            class: {
                              "guide-item--active":
                                _vm.guide &&
                                _vm.supplier.category != null &&
                                _vm.currentGuideStep == 2,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "supplier__search",
                                class: { "guide-item__content": _vm.guide },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.openLocationModal($event)
                                      },
                                    },
                                  },
                                  [_vm._v("Click here")]
                                ),
                                _vm._v(
                                  " to select location by clicking on\n                    map\n                  "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "supplier-content__data supplier__contacts-address",
                                class: { "guide-item__content": _vm.guide },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "supplier-content__data-label",
                                    class: {
                                      supplier__nodata:
                                        !_vm.supplier.fullAddress,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      Address\n                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "supplier-content__data-content",
                                    class: {
                                      supplier__nodata:
                                        !_vm.supplier.fullAddress,
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.supplier.fullAddress,
                                          expression: "supplier.fullAddress",
                                          modifiers: { trim: true },
                                        },
                                      ],
                                      ref: "addressAutocomplete",
                                      staticClass: "supplier__input",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Enter address",
                                        "data-field": "address",
                                      },
                                      domProps: {
                                        value: _vm.supplier.fullAddress,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.supplier,
                                            "fullAddress",
                                            $event.target.value.trim()
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.addressError && _vm.validate
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "supplier__error",
                                        attrs: { "data-error": "address" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                      Address is required\n                    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "supplier-content__data-label",
                                    class: {
                                      supplier__nodata: !_vm.supplier.city,
                                    },
                                  },
                                  [_vm._v("City")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "supplier-content__data-content",
                                    class: {
                                      supplier__nodata: !_vm.supplier.city,
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.supplier.city,
                                          expression: "supplier.city",
                                          modifiers: { trim: true },
                                        },
                                      ],
                                      ref: "cityAutocomplete",
                                      staticClass: "supplier__input",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Enter city",
                                        "data-field": "city",
                                      },
                                      domProps: { value: _vm.supplier.city },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.supplier,
                                            "city",
                                            $event.target.value.trim()
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "supplier-content__data-label",
                                    class: {
                                      supplier__nodata: !_vm.searchCountry,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      Country\n                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "supplier-content__data-content",
                                    class: {
                                      supplier__nodata: !_vm.searchCountry,
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.searchCountry,
                                          expression: "searchCountry",
                                          modifiers: { trim: true },
                                        },
                                      ],
                                      ref: "countryAutocomplete",
                                      staticClass: "supplier__input",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Select country",
                                        "data-field": "country",
                                        autocomplete: "off",
                                      },
                                      domProps: { value: _vm.searchCountry },
                                      on: {
                                        focus: _vm.initCountriesAutocomplete,
                                        blur: [
                                          _vm.destroyCountriesAutocomplete,
                                          function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        ],
                                        keydown: _vm.countriesHandleKeydown,
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.searchCountry =
                                              $event.target.value.trim()
                                          },
                                          _vm.filterCountries,
                                        ],
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.filteredCountries &&
                                    _vm.filteredCountries.length &&
                                    _vm.countriesAutcompleteVisible
                                      ? _c(
                                          "div",
                                          { staticClass: "pac-container" },
                                          _vm._l(
                                            _vm.filteredCountries,
                                            function (country, i) {
                                              return _c(
                                                "div",
                                                {
                                                  key: "country" + i,
                                                  staticClass: "pac-item",
                                                  class: {
                                                    "pac-item-selected":
                                                      i ===
                                                      _vm.selectedCountryIndex,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.selectCountry(
                                                        country
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "pac-item-query",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                            " +
                                                          _vm._s(country) +
                                                          "\n                          "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                (_vm.countryError || !_vm.searchCountry) &&
                                _vm.validate
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "supplier__error",
                                        attrs: { "data-error": "country" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                      Country is required\n                    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "supplier-content__data-label",
                                    class: {
                                      supplier__nodata: !(_vm.$store.user
                                        .chineese
                                        ? _vm.baidu.coordinatesAsText
                                        : _vm.supplier.coordinates),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      Coordinates\n                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "supplier-content__data-content",
                                    class: {
                                      supplier__nodata: !(_vm.$store.user
                                        .chineese
                                        ? _vm.baidu.coordinatesAsText
                                        : _vm.supplier.coordinates),
                                    },
                                  },
                                  [
                                    !_vm.$store.user.chineese
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.trim",
                                              value: _vm.supplier.coordinates,
                                              expression:
                                                "supplier.coordinates",
                                              modifiers: { trim: true },
                                            },
                                          ],
                                          ref: "coordinates",
                                          staticClass: "supplier__input",
                                          attrs: {
                                            type: "text",
                                            placeholder: "Enter coordinates",
                                            "data-field": "coordinates",
                                          },
                                          domProps: {
                                            value: _vm.supplier.coordinates,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.supplier,
                                                "coordinates",
                                                $event.target.value.trim()
                                              )
                                            },
                                            blur: function ($event) {
                                              return _vm.$forceUpdate()
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.$store.user.chineese
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.trim",
                                              value:
                                                _vm.baidu.coordinatesAsText,
                                              expression:
                                                "baidu.coordinatesAsText",
                                              modifiers: { trim: true },
                                            },
                                          ],
                                          ref: "coordinates",
                                          staticClass: "supplier__input",
                                          attrs: {
                                            type: "text",
                                            placeholder: "Enter coordinates",
                                            "data-field": "coordinates",
                                          },
                                          domProps: {
                                            value: _vm.baidu.coordinatesAsText,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.baidu,
                                                "coordinatesAsText",
                                                $event.target.value.trim()
                                              )
                                            },
                                            blur: function ($event) {
                                              return _vm.$forceUpdate()
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.coordinatesError && _vm.validate
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "supplier__error",
                                        attrs: { "data-error": "coordinates" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                      Coordinates are not correct\n                    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.guide &&
                            _vm.supplier.category != null &&
                            _vm.currentGuideStep == 2
                              ? _c("TourTooltip", {
                                  attrs: {
                                    title: "Enter Address",
                                    message:
                                      "There are 3 ways to enter your adrress: Directly in the text field, by Entering coordinates or just Click on the map.",
                                    currentStep: _vm.currentStep,
                                    guideSteps: _vm.guideSteps,
                                    position: "bottom",
                                    arrow: "left",
                                  },
                                  on: {
                                    close: _vm.guideClose,
                                    next: _vm.guideNext,
                                    back: _vm.guideBack,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "supplier__contacts-lists" }, [
                          _c(
                            "div",
                            {
                              ref: "guideItem3",
                              staticClass: "guide-item supplier__contacts-list",
                              class: {
                                "guide-item--active":
                                  _vm.guide &&
                                  _vm.supplier.category != null &&
                                  _vm.currentGuideStep == 3,
                              },
                            },
                            [
                              _c(
                                "ul",
                                { class: { "guide-item__content": _vm.guide } },
                                [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "supplier__contacts-item",
                                      class: {
                                        supplier__nodata: !_vm.supplier.web,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "supplier__contacts-link",
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "supplier__contacts-icon",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.svg.siteIcon
                                              ),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value: _vm.supplier.web,
                                                expression: "supplier.web",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "supplier__input",
                                            attrs: {
                                              type: "url",
                                              placeholder: "Enter homepage",
                                              "data-field": "homepage",
                                            },
                                            domProps: {
                                              value: _vm.supplier.web,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.supplier,
                                                  "web",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.homepageError && _vm.validate
                                    ? _c(
                                        "li",
                                        {
                                          staticClass: "supplier__error",
                                          attrs: { "data-error": "homepage" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Url is not valid\n                      "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "supplier__contacts-item",
                                      class: {
                                        supplier__nodata: !_vm.supplier.email,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "supplier__contacts-link",
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "supplier__contacts-icon",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.svg.emailIcon
                                              ),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value: _vm.supplier.email,
                                                expression: "supplier.email",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "supplier__input",
                                            attrs: {
                                              type: "email",
                                              placeholder:
                                                "Enter email address",
                                              "data-field": "email",
                                            },
                                            domProps: {
                                              value: _vm.supplier.email,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.supplier,
                                                  "email",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.emailError && _vm.validate
                                    ? _c(
                                        "li",
                                        {
                                          staticClass: "supplier__error",
                                          attrs: { "data-error": "email" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Email is not valid\n                      "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "supplier__contacts-item",
                                      class: {
                                        supplier__nodata: !_vm.supplier.phone,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "supplier__contacts-link",
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "supplier__contacts-icon",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.svg.phoneIcon
                                              ),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value: _vm.supplier.phone,
                                                expression: "supplier.phone",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "supplier__input",
                                            attrs: {
                                              type: "tel",
                                              placeholder: "Enter phone number",
                                              "data-field": "phone",
                                            },
                                            domProps: {
                                              value: _vm.supplier.phone,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.supplier,
                                                  "phone",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.phoneError && _vm.validate
                                    ? _c(
                                        "li",
                                        {
                                          staticClass: "supplier__error",
                                          attrs: { "data-error": "phone" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Phone number is not valid\n                      "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "supplier__contacts-item",
                                      class: {
                                        supplier__nodata:
                                          !_vm.supplier.whatsApp,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "supplier__contacts-link",
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "supplier__contacts-icon",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.svg.whatsApp
                                              ),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value: _vm.supplier.whatsApp,
                                                expression: "supplier.whatsApp",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "supplier__input",
                                            attrs: {
                                              type: "text",
                                              placeholder: "Enter WhatsApp",
                                            },
                                            domProps: {
                                              value: _vm.supplier.whatsApp,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.supplier,
                                                  "whatsApp",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "supplier__contacts-item",
                                      class: {
                                        supplier__nodata: !_vm.supplier.weChat,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "supplier__contacts-link",
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "supplier__contacts-icon",
                                            domProps: {
                                              innerHTML: _vm._s(_vm.svg.weChat),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value: _vm.supplier.weChat,
                                                expression: "supplier.weChat",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "supplier__input",
                                            attrs: {
                                              type: "text",
                                              placeholder: "Enter WeChat",
                                            },
                                            domProps: {
                                              value: _vm.supplier.weChat,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.supplier,
                                                  "weChat",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "supplier__contacts-item",
                                      class: {
                                        supplier__nodata: !_vm.supplier.skype,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "supplier__contacts-link",
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "supplier__contacts-icon",
                                            domProps: {
                                              innerHTML: _vm._s(_vm.svg.skype),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value: _vm.supplier.skype,
                                                expression: "supplier.skype",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "supplier__input",
                                            attrs: {
                                              type: "text",
                                              placeholder: "Enter Skype",
                                            },
                                            domProps: {
                                              value: _vm.supplier.skype,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.supplier,
                                                  "skype",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.guide &&
                              _vm.supplier.category != null &&
                              _vm.currentGuideStep == 3
                                ? _c("TourTooltip", {
                                    attrs: {
                                      title: "Enter Contact Information",
                                      message:
                                        "Make yourself available for contact by entering contact information. The more details the easier to contact.",
                                      currentStep: _vm.currentStep,
                                      guideSteps: _vm.guideSteps,
                                      position: "bottom",
                                      arrow: "left",
                                    },
                                    on: {
                                      close: _vm.guideClose,
                                      next: _vm.guideNext,
                                      back: _vm.guideBack,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("ul", { staticClass: "supplier__contacts-list" }, [
                            _c(
                              "li",
                              {
                                staticClass: "supplier__contacts-item",
                                class: {
                                  supplier__nodata: !_vm.supplier.linkedIn,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "supplier__contacts-link" },
                                  [
                                    _c("i", {
                                      staticClass: "supplier__contacts-icon",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.svg.linkedIn),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.supplier.linkedIn,
                                          expression: "supplier.linkedIn",
                                          modifiers: { trim: true },
                                        },
                                      ],
                                      staticClass: "supplier__input",
                                      attrs: {
                                        type: "url",
                                        placeholder: "Add LinkedIn profile",
                                        "data-field": "linkedIn",
                                      },
                                      domProps: {
                                        value: _vm.supplier.linkedIn,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.supplier,
                                            "linkedIn",
                                            $event.target.value.trim()
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.linkedInError && _vm.validate
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "supplier__error",
                                    attrs: { "data-error": "linkedIn" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      Url is not valid\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass: "supplier__contacts-item",
                                class: {
                                  supplier__nodata: !_vm.supplier.facebook,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "supplier__contacts-link" },
                                  [
                                    _c("i", {
                                      staticClass: "supplier__contacts-icon",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.svg.facebook),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.supplier.facebook,
                                          expression: "supplier.facebook",
                                          modifiers: { trim: true },
                                        },
                                      ],
                                      staticClass: "supplier__input",
                                      attrs: {
                                        type: "url",
                                        placeholder: "Add Facebook profile",
                                        "data-field": "facebook",
                                      },
                                      domProps: {
                                        value: _vm.supplier.facebook,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.supplier,
                                            "facebook",
                                            $event.target.value.trim()
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.facebookError && _vm.validate
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "supplier__error",
                                    attrs: { "data-error": "facebook" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      Url is not valid\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass: "supplier__contacts-item",
                                class: {
                                  "supplier__contacts-item--hidden":
                                    !_vm.showSocialLinksOnMobile,
                                  supplier__nodata: !_vm.supplier.instagram,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "supplier__contacts-link" },
                                  [
                                    _c("i", {
                                      staticClass: "supplier__contacts-icon",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.svg.instagram),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.supplier.instagram,
                                          expression: "supplier.instagram",
                                          modifiers: { trim: true },
                                        },
                                      ],
                                      staticClass: "supplier__input",
                                      attrs: {
                                        type: "url",
                                        placeholder: "Add Instagram profile",
                                        "data-field": "instagram",
                                      },
                                      domProps: {
                                        value: _vm.supplier.instagram,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.supplier,
                                            "instagram",
                                            $event.target.value.trim()
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.instagramError && _vm.validate
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "supplier__error",
                                    attrs: { "data-error": "instagram" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      Url is not valid\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass: "supplier__contacts-item",
                                class: {
                                  "supplier__contacts-item--hidden":
                                    !_vm.showSocialLinksOnMobile,
                                  supplier__nodata: !_vm.supplier.pinterest,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "supplier__contacts-link" },
                                  [
                                    _c("i", {
                                      staticClass: "supplier__contacts-icon",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.svg.pinterest),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.supplier.pinterest,
                                          expression: "supplier.pinterest",
                                          modifiers: { trim: true },
                                        },
                                      ],
                                      staticClass: "supplier__input",
                                      attrs: {
                                        type: "url",
                                        placeholder: "Add Pinterest profile",
                                        "data-field": "pinterest",
                                      },
                                      domProps: {
                                        value: _vm.supplier.pinterest,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.supplier,
                                            "pinterest",
                                            $event.target.value.trim()
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.pinterestError && _vm.validate
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "supplier__error",
                                    attrs: { "data-error": "pinterest" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      Url is not valid\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass: "supplier__contacts-item",
                                class: {
                                  "supplier__contacts-item--hidden":
                                    !_vm.showSocialLinksOnMobile,
                                  supplier__nodata: !_vm.supplier.twitter,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "supplier__contacts-link" },
                                  [
                                    _c("i", {
                                      staticClass: "supplier__contacts-icon",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.svg.twitter),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.supplier.twitter,
                                          expression: "supplier.twitter",
                                          modifiers: { trim: true },
                                        },
                                      ],
                                      staticClass: "supplier__input",
                                      attrs: {
                                        type: "url",
                                        placeholder: "Add Twitter profile",
                                        "data-field": "twitter",
                                      },
                                      domProps: { value: _vm.supplier.twitter },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.supplier,
                                            "twitter",
                                            $event.target.value.trim()
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.twitterError && _vm.validate
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "supplier__error",
                                    attrs: { "data-error": "twitter" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      Url is not valid\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass: "supplier__contacts-item",
                                class: {
                                  "supplier__contacts-item--hidden":
                                    !_vm.showSocialLinksOnMobile,
                                  supplier__nodata: !_vm.supplier.googlePlus,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "supplier__contacts-link" },
                                  [
                                    _c("i", {
                                      staticClass: "supplier__contacts-icon",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.svg.googlePlus),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.supplier.googlePlus,
                                          expression: "supplier.googlePlus",
                                          modifiers: { trim: true },
                                        },
                                      ],
                                      staticClass: "supplier__input",
                                      attrs: {
                                        type: "url",
                                        placeholder: "Add Google+ profile",
                                        "data-field": "googlePlus",
                                      },
                                      domProps: {
                                        value: _vm.supplier.googlePlus,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.supplier,
                                            "googlePlus",
                                            $event.target.value.trim()
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.googlePlusError && _vm.validate
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "supplier__error",
                                    attrs: { "data-error": "googlePlus" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      Url is not valid\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass: "supplier__contacts-item",
                                class: {
                                  "supplier__contacts-item--hidden":
                                    !_vm.showSocialLinksOnMobile,
                                  supplier__nodata: !_vm.supplier.youTube,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "supplier__contacts-link" },
                                  [
                                    _c("i", {
                                      staticClass: "supplier__contacts-icon",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.svg.youtube),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.supplier.youTube,
                                          expression: "supplier.youTube",
                                          modifiers: { trim: true },
                                        },
                                      ],
                                      staticClass: "supplier__input",
                                      attrs: {
                                        type: "url",
                                        placeholder: "Add YouTube profile",
                                        "data-field": "youtube",
                                      },
                                      domProps: { value: _vm.supplier.youTube },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.supplier,
                                            "youTube",
                                            $event.target.value.trim()
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.youtubeError && _vm.validate
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "supplier__error",
                                    attrs: { "data-error": "youtube" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      Url is not valid\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass:
                                  "supplier__contacts-item supplier__contacts-item-sm",
                                class: {
                                  "supplier__contacts-item--hidden":
                                    _vm.showSocialLinksOnMobile,
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        _vm.showSocialLinksOnMobile = true
                                      },
                                    },
                                  },
                                  [_vm._v("Show more")]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "h2",
                    {
                      class: {
                        supplier__nodata:
                          _vm.footwearTypesError &&
                          _vm.processError &&
                          _vm.genderError &&
                          _vm.supplier.lastingMethods &&
                          !_vm.supplier.lastingMethods.length &&
                          _vm.supplier.niche &&
                          !_vm.supplier.niche.length &&
                          _vm.supplier.capabilities &&
                          !_vm.supplier.capabilities.length &&
                          _vm.supplier.category == null,
                      },
                      attrs: { id: "production" },
                    },
                    [_vm._v("\n              Production\n            ")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "supplier-content__data" }, [
                    _c(
                      "div",
                      {
                        staticClass: "supplier-content__data-label",
                        class: {
                          supplier__nodata:
                            _vm.supplier.companyStructure == null,
                        },
                      },
                      [
                        _vm._v(
                          "\n                Company Structure\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "supplier-content__data-content supplier__grid",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "supplier__grid-item supplier__grid-item--small",
                          },
                          [
                            _c("label", { staticClass: "supplier__radio" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.supplier.companyStructure,
                                    expression: "supplier.companyStructure",
                                  },
                                ],
                                attrs: { type: "radio", value: "0" },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.supplier.companyStructure,
                                    "0"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.supplier,
                                      "companyStructure",
                                      "0"
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v("Manufacturer")]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "supplier__grid-item supplier__grid-item--small",
                          },
                          [
                            _c("label", { staticClass: "supplier__radio" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.supplier.companyStructure,
                                    expression: "supplier.companyStructure",
                                  },
                                ],
                                attrs: { type: "radio", value: "1" },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.supplier.companyStructure,
                                    "1"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.supplier,
                                      "companyStructure",
                                      "1"
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v("Agent")]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "supplier-content__data-label",
                        class: {
                          supplier__nodata: _vm.supplier.category == null,
                        },
                      },
                      [_vm._v("\n                Producing\n              ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "supplier-content__data-content supplier__grid",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "supplier__grid-item supplier__grid-item--small",
                          },
                          [
                            _c("label", { staticClass: "supplier__radio" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.supplier.category,
                                    expression: "supplier.category",
                                  },
                                ],
                                attrs: { type: "radio", value: "0" },
                                domProps: {
                                  checked: _vm._q(_vm.supplier.category, "0"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.supplier,
                                      "category",
                                      "0"
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v("Footwear")]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "supplier__grid-item supplier__grid-item--large",
                          },
                          [
                            _c("label", { staticClass: "supplier__radio" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.supplier.category,
                                    expression: "supplier.category",
                                  },
                                ],
                                attrs: { type: "radio", value: "1" },
                                domProps: {
                                  checked: _vm._q(_vm.supplier.category, "1"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.supplier,
                                      "category",
                                      "1"
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v("Components & Services")]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.supplier.category == 0
                    ? _c("div", { staticClass: "supplier-content__data" }, [
                        _c(
                          "div",
                          {
                            ref: "guideItem4",
                            staticClass: "guide-item",
                            class: {
                              "guide-item--active":
                                _vm.guide &&
                                _vm.supplier.category != null &&
                                _vm.currentGuideStep == 4,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "supplier-content__data",
                                class: { "guide-item__content": _vm.guide },
                                attrs: { "data-field": "footwearTypes" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "supplier-content__data-label",
                                    class: {
                                      supplier__nodata: _vm.footwearTypesError,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Footwear Types\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "supplier-content__data-content supplier__grid",
                                  },
                                  [
                                    _vm._l(
                                      _vm.footwearTypesArticles,
                                      function (article, i) {
                                        return _c(
                                          "div",
                                          {
                                            key: i,
                                            staticClass:
                                              "supplier__grid-item supplier__grid-item--large",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "supplier__checkbox",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.selectedFootwearTypes,
                                                      expression:
                                                        "selectedFootwearTypes",
                                                    },
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    value: article.id,
                                                    checked: Array.isArray(
                                                      _vm.selectedFootwearTypes
                                                    )
                                                      ? _vm._i(
                                                          _vm.selectedFootwearTypes,
                                                          article.id
                                                        ) > -1
                                                      : _vm.selectedFootwearTypes,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.selectedFootwearTypes,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = article.id,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.selectedFootwearTypes =
                                                              $$a.concat([$$v]))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.selectedFootwearTypes =
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                ))
                                                        }
                                                      } else {
                                                        _vm.selectedFootwearTypes =
                                                          $$c
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(_vm._s(article.title)),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm.footwearTypesError && _vm.validate
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "supplier__error",
                                            attrs: {
                                              "data-error": "footwearTypes",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      Footwear types are required\n                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.guide &&
                            _vm.supplier.category != null &&
                            _vm.currentGuideStep == 4
                              ? _c("TourTooltip", {
                                  attrs: {
                                    title: "Select Footwear Types",
                                    message:
                                      "Which footwear types can you handle? Enter by pressing the check boxes.",
                                    currentStep: _vm.currentStep,
                                    guideSteps: _vm.guideSteps,
                                    position: "bottom",
                                    arrow: "center",
                                  },
                                  on: {
                                    close: _vm.guideClose,
                                    next: _vm.guideNext,
                                    back: _vm.guideBack,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            ref: "guideItem5",
                            staticClass: "guide-item",
                            class: {
                              "guide-item--active":
                                _vm.guide &&
                                _vm.supplier.category != null &&
                                _vm.currentGuideStep == 5,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "supplier-content__data",
                                class: { "guide-item__content": _vm.guide },
                                attrs: { "data-field": "process" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "supplier-content__data-label",
                                    class: {
                                      supplier__nodata: _vm.processError,
                                    },
                                  },
                                  [_vm._v("Process")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "supplier-content__data-content supplier__grid",
                                  },
                                  [
                                    _vm._l(
                                      _vm.processesArticles,
                                      function (article, i) {
                                        return _c(
                                          "div",
                                          {
                                            key: i,
                                            staticClass:
                                              "supplier__grid-item supplier__grid-item--large",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "supplier__checkbox",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.selectedProcesses,
                                                      expression:
                                                        "selectedProcesses",
                                                    },
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    value: article.id,
                                                    checked: Array.isArray(
                                                      _vm.selectedProcesses
                                                    )
                                                      ? _vm._i(
                                                          _vm.selectedProcesses,
                                                          article.id
                                                        ) > -1
                                                      : _vm.selectedProcesses,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.selectedProcesses,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = article.id,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.selectedProcesses =
                                                              $$a.concat([$$v]))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.selectedProcesses =
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                ))
                                                        }
                                                      } else {
                                                        _vm.selectedProcesses =
                                                          $$c
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(_vm._s(article.title)),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm.processError && _vm.validate
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "supplier__error",
                                            attrs: { "data-error": "process" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      Process is required\n                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.guide &&
                            _vm.supplier.category != null &&
                            _vm.currentGuideStep == 5
                              ? _c("TourTooltip", {
                                  attrs: {
                                    title: "Select Processes",
                                    message:
                                      "Enter by pressing the check boxes for the processes you can produce/handle.",
                                    currentStep: _vm.currentStep,
                                    guideSteps: _vm.guideSteps,
                                    position: "bottom",
                                    arrow: "center",
                                  },
                                  on: {
                                    close: _vm.guideClose,
                                    next: _vm.guideNext,
                                    back: _vm.guideBack,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            ref: "guideItem6",
                            staticClass: "guide-item",
                            class: {
                              "guide-item--active":
                                _vm.guide &&
                                _vm.supplier.category != null &&
                                _vm.currentGuideStep == 6,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "supplier-content__data",
                                class: { "guide-item__content": _vm.guide },
                                attrs: { "data-field": "gender" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "supplier-content__data-label",
                                    class: {
                                      supplier__nodata: _vm.genderError,
                                    },
                                  },
                                  [_vm._v("Gender")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "supplier-content__data-content supplier__grid",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "supplier__grid-item supplier__grid-item--large",
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "supplier__checkbox" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.supplier.men,
                                                  expression: "supplier.men",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.supplier.men
                                                )
                                                  ? _vm._i(
                                                      _vm.supplier.men,
                                                      null
                                                    ) > -1
                                                  : _vm.supplier.men,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.supplier.men,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.supplier,
                                                          "men",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.supplier,
                                                          "men",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.supplier,
                                                      "men",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("Men")]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "supplier__grid-item supplier__grid-item--large",
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "supplier__checkbox" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.supplier.ladies,
                                                  expression: "supplier.ladies",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.supplier.ladies
                                                )
                                                  ? _vm._i(
                                                      _vm.supplier.ladies,
                                                      null
                                                    ) > -1
                                                  : _vm.supplier.ladies,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.supplier.ladies,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.supplier,
                                                          "ladies",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.supplier,
                                                          "ladies",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.supplier,
                                                      "ladies",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("Ladies")]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "supplier__grid-item supplier__grid-item--large",
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "supplier__checkbox" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.supplier.kids,
                                                  expression: "supplier.kids",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.supplier.kids
                                                )
                                                  ? _vm._i(
                                                      _vm.supplier.kids,
                                                      null
                                                    ) > -1
                                                  : _vm.supplier.kids,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.supplier.kids,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.supplier,
                                                          "kids",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.supplier,
                                                          "kids",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.supplier,
                                                      "kids",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v("Boys & Girls"),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "supplier__grid-item supplier__grid-item--large",
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "supplier__checkbox" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.supplier.babies,
                                                  expression: "supplier.babies",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.supplier.babies
                                                )
                                                  ? _vm._i(
                                                      _vm.supplier.babies,
                                                      null
                                                    ) > -1
                                                  : _vm.supplier.babies,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.supplier.babies,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.supplier,
                                                          "babies",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.supplier,
                                                          "babies",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.supplier,
                                                      "babies",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("Babies")]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.genderError && _vm.validate
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "supplier__error",
                                            attrs: { "data-error": "gender" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      Gender is required\n                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.guide &&
                            _vm.supplier.category != null &&
                            _vm.currentGuideStep == 6
                              ? _c("TourTooltip", {
                                  attrs: {
                                    title: "Select Gender",
                                    message:
                                      "Select which genders and age categories you can produce and/or handle.",
                                    currentStep: _vm.currentStep,
                                    guideSteps: _vm.guideSteps,
                                    position: "bottom",
                                    arrow: "center",
                                  },
                                  on: {
                                    close: _vm.guideClose,
                                    next: _vm.guideNext,
                                    back: _vm.guideBack,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "supplier-content__data-label",
                            class: {
                              supplier__nodata:
                                !_vm.supplier.lastingMethods.length,
                            },
                          },
                          [
                            _vm._v(
                              "\n                Lasting Method\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "supplier-content__data-content supplier__grid",
                          },
                          _vm._l(
                            _vm.filters.lastingMethods,
                            function (item, i) {
                              return _c(
                                "div",
                                {
                                  key: i,
                                  staticClass:
                                    "supplier__grid-item supplier__grid-item--large",
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "supplier__checkbox" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.supplier.lastingMethods,
                                            expression:
                                              "supplier.lastingMethods",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          value: item,
                                          checked: Array.isArray(
                                            _vm.supplier.lastingMethods
                                          )
                                            ? _vm._i(
                                                _vm.supplier.lastingMethods,
                                                item
                                              ) > -1
                                            : _vm.supplier.lastingMethods,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.supplier.lastingMethods,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = item,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.supplier,
                                                    "lastingMethods",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.supplier,
                                                    "lastingMethods",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.supplier,
                                                "lastingMethods",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v(_vm._s(item.title))]),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "supplier-content__data-label",
                            class: {
                              supplier__nodata: !_vm.supplier.niche.length,
                            },
                          },
                          [_vm._v("\n                Niche\n              ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "supplier-content__data-content supplier__grid",
                          },
                          _vm._l(_vm.filters.niche, function (item, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass:
                                  "supplier__grid-item supplier__grid-item--large",
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "supplier__checkbox" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.supplier.niche,
                                          expression: "supplier.niche",
                                        },
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        value: item,
                                        checked: Array.isArray(
                                          _vm.supplier.niche
                                        )
                                          ? _vm._i(_vm.supplier.niche, item) >
                                            -1
                                          : _vm.supplier.niche,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.supplier.niche,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = item,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.supplier,
                                                  "niche",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.supplier,
                                                  "niche",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(_vm.supplier, "niche", $$c)
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(item.title))]),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            ref: "guideItem7",
                            staticClass: "guide-item",
                            class: {
                              "guide-item--active":
                                _vm.guide &&
                                _vm.supplier.category != null &&
                                _vm.currentGuideStep == 7,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "supplier-content__data",
                                class: { "guide-item__content": _vm.guide },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "supplier-content__data-label",
                                    class: {
                                      supplier__nodata:
                                        !_vm.supplier.capabilities.length,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Capabilities\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.supplier.capabilities
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "supplier-content__data-content supplier__grid supplier__grid",
                                      },
                                      _vm._l(
                                        _vm.filters.capabilities,
                                        function (item, i) {
                                          return i == 0 ||
                                            item.category !=
                                              _vm.filters.capabilities[i - 1]
                                                .category
                                            ? _c(
                                                "div",
                                                {
                                                  key: i,
                                                  staticClass:
                                                    "supplier__grid-group",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "supplier__grid-group-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.category)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "supplier__grid-group-list",
                                                    },
                                                    _vm._l(
                                                      _vm.filters.capabilities.filter(
                                                        function (i) {
                                                          return (
                                                            i.category ==
                                                            item.category
                                                          )
                                                        }
                                                      ),
                                                      function (item, i) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: i,
                                                            staticClass:
                                                              "supplier__grid-item supplier__grid-item--large",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "supplier__checkbox",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .supplier
                                                                          .capabilities,
                                                                      expression:
                                                                        "supplier.capabilities",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                  },
                                                                  domProps: {
                                                                    value: item,
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm
                                                                          .supplier
                                                                          .capabilities
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm
                                                                              .supplier
                                                                              .capabilities,
                                                                            item
                                                                          ) > -1
                                                                        : _vm
                                                                            .supplier
                                                                            .capabilities,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm
                                                                              .supplier
                                                                              .capabilities,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? true
                                                                              : false
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              item,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              _vm.$set(
                                                                                _vm.supplier,
                                                                                "capabilities",
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                )
                                                                              )
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              _vm.$set(
                                                                                _vm.supplier,
                                                                                "capabilities",
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  )
                                                                              )
                                                                          }
                                                                        } else {
                                                                          _vm.$set(
                                                                            _vm.supplier,
                                                                            "capabilities",
                                                                            $$c
                                                                          )
                                                                        }
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.title
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.guide && _vm.currentGuideStep == 7
                              ? _c("TourTooltip", {
                                  attrs: {
                                    title:
                                      "Specify Which Capabilities You Support With",
                                    message:
                                      "Select in which fields a potential buyer can expect to get support in from you.",
                                    currentStep: _vm.currentStep,
                                    guideSteps: _vm.guideSteps,
                                    position: "top",
                                    arrow: "center",
                                  },
                                  on: {
                                    close: _vm.guideClose,
                                    next: _vm.guideNext,
                                    back: _vm.guideBack,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.supplier.category == 1
                    ? _c("div", { staticClass: "supplier-content__data" }, [
                        _c(
                          "div",
                          {
                            staticClass: "supplier-content__data-label",
                            class: {
                              supplier__nodata: !(
                                _vm.supplier.materialLeather ||
                                _vm.supplier.materialTextile ||
                                _vm.supplier.materialSynthetic ||
                                _vm.supplier.trimsMetal ||
                                _vm.supplier.trimsShoelaces ||
                                _vm.supplier.trimsToePuffsStiffners ||
                                _vm.supplier.trimsInsoles ||
                                _vm.supplier.solesHeelsAndRelatedMidsoles ||
                                _vm.supplier.solesHeelsAndRelatedOutsoles ||
                                _vm.supplier.solesHeelsAndRelatedHeels ||
                                _vm.supplier.solesHeelsAndRelatedWelts ||
                                _vm.supplier.lastsAndRelatedLasts ||
                                _vm.supplier.lastsAndRelatedShanks ||
                                _vm.supplier.logisticStickers ||
                                _vm.supplier.logisticPackaging
                              ),
                            },
                          },
                          [
                            _vm._v(
                              "\n                Type of production\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "supplier-content__data-content" },
                          _vm._l(
                            _vm.$store.suppliers.typeOfProduction,
                            function (item, i) {
                              return _c(
                                "div",
                                { key: i, staticClass: "cstop" },
                                [
                                  _c("div", { staticClass: "cstop__label" }, [
                                    _vm._v(_vm._s(item.title)),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(item.options, function (category, i) {
                                    return _c(
                                      "div",
                                      { key: i, staticClass: "cstop__item" },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "supplier__checkbox" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.supplier[category.key],
                                                  expression:
                                                    "supplier[category.key]",
                                                },
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                "data-key": category.key,
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.supplier[category.key]
                                                )
                                                  ? _vm._i(
                                                      _vm.supplier[
                                                        category.key
                                                      ],
                                                      null
                                                    ) > -1
                                                  : _vm.supplier[category.key],
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$a =
                                                        _vm.supplier[
                                                          category.key
                                                        ],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.supplier,
                                                            category.key,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.supplier,
                                                            category.key,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.supplier,
                                                        category.key,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                  _vm.cstopHandler,
                                                ],
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(category.title)),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.supplier[category.key]
                                          ? _c(
                                              "div",
                                              { staticClass: "cstop__grid" },
                                              _vm._l(
                                                category.options,
                                                function (group, i) {
                                                  return _c("div", { key: i }, [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "cstop__label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            group.groupTitle
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "supplier__grid-group-list",
                                                      },
                                                      _vm._l(
                                                        group.options,
                                                        function (option, i) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: i,
                                                              class:
                                                                "supplier__grid-item supplier__grid-item--" +
                                                                group.gridSize,
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "supplier__checkbox",
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .supplier[
                                                                              option
                                                                                .key
                                                                            ],
                                                                          expression:
                                                                            "supplier[option.key]",
                                                                        },
                                                                      ],
                                                                    attrs: {
                                                                      type: "checkbox",
                                                                      "data-group":
                                                                        category.key,
                                                                      "data-key":
                                                                        option.key,
                                                                    },
                                                                    domProps: {
                                                                      checked:
                                                                        Array.isArray(
                                                                          _vm
                                                                            .supplier[
                                                                            option
                                                                              .key
                                                                          ]
                                                                        )
                                                                          ? _vm._i(
                                                                              _vm
                                                                                .supplier[
                                                                                option
                                                                                  .key
                                                                              ],
                                                                              null
                                                                            ) >
                                                                            -1
                                                                          : _vm
                                                                              .supplier[
                                                                              option
                                                                                .key
                                                                            ],
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$a =
                                                                              _vm
                                                                                .supplier[
                                                                                option
                                                                                  .key
                                                                              ],
                                                                            $$el =
                                                                              $event.target,
                                                                            $$c =
                                                                              $$el.checked
                                                                                ? true
                                                                                : false
                                                                          if (
                                                                            Array.isArray(
                                                                              $$a
                                                                            )
                                                                          ) {
                                                                            var $$v =
                                                                                null,
                                                                              $$i =
                                                                                _vm._i(
                                                                                  $$a,
                                                                                  $$v
                                                                                )
                                                                            if (
                                                                              $$el.checked
                                                                            ) {
                                                                              $$i <
                                                                                0 &&
                                                                                _vm.$set(
                                                                                  _vm.supplier,
                                                                                  option.key,
                                                                                  $$a.concat(
                                                                                    [
                                                                                      $$v,
                                                                                    ]
                                                                                  )
                                                                                )
                                                                            } else {
                                                                              $$i >
                                                                                -1 &&
                                                                                _vm.$set(
                                                                                  _vm.supplier,
                                                                                  option.key,
                                                                                  $$a
                                                                                    .slice(
                                                                                      0,
                                                                                      $$i
                                                                                    )
                                                                                    .concat(
                                                                                      $$a.slice(
                                                                                        $$i +
                                                                                          1
                                                                                      )
                                                                                    )
                                                                                )
                                                                            }
                                                                          } else {
                                                                            _vm.$set(
                                                                              _vm.supplier,
                                                                              option.key,
                                                                              $$c
                                                                            )
                                                                          }
                                                                        },
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        option.title
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              )
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "guideItem8",
                      staticClass: "guide-item",
                      class: {
                        "guide-item--active":
                          _vm.guide &&
                          _vm.supplier.category != null &&
                          _vm.currentGuideStep == 8,
                      },
                    },
                    [
                      _c(
                        "div",
                        { class: { "guide-item__content": _vm.guide } },
                        [
                          _c(
                            "h2",
                            {
                              class: {
                                supplier__nodata:
                                  !_vm.supplier.founded &&
                                  !_vm.supplier.employees &&
                                  !_vm.supplier.moq &&
                                  !_vm.supplier.dailyProduction &&
                                  !_vm.supplier.materialExpertiseTextile &&
                                  !_vm.supplier.materialExpertiseLeather &&
                                  !_vm.supplier.materialExpertiseSynthetic &&
                                  !_vm.supplier.sizeRangeFrom &&
                                  !_vm.supplier.sizeRangeTo,
                              },
                              attrs: { id: "info" },
                            },
                            [
                              _vm._v(
                                "\n                  Company information\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "supplier-content__data" }, [
                            _c(
                              "div",
                              {
                                staticClass: "supplier-content__data-label",
                                class: {
                                  supplier__nodata: !_vm.supplier.founded,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    Founded\n                  "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "supplier-content__data-content",
                                class: {
                                  supplier__nodata: !_vm.supplier.founded,
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.supplier.founded,
                                      expression: "supplier.founded",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  staticClass: "supplier__input",
                                  attrs: {
                                    type: "text",
                                    placeholder:
                                      "Click here to add information",
                                  },
                                  domProps: { value: _vm.supplier.founded },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.supplier,
                                        "founded",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "supplier-content__data-label",
                                class: {
                                  supplier__nodata: !_vm.supplier.employees,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    Employees\n                  "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "supplier-content__data-content",
                                class: {
                                  supplier__nodata: !_vm.supplier.employees,
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.supplier.employees,
                                      expression: "supplier.employees",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  staticClass: "supplier__input",
                                  attrs: {
                                    type: "text",
                                    placeholder:
                                      "Click here to add information",
                                  },
                                  domProps: { value: _vm.supplier.employees },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.supplier,
                                        "employees",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.supplier.category == 0
                            ? _c(
                                "div",
                                { staticClass: "supplier-content__data" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "supplier-content__data-label",
                                      class: {
                                        supplier__nodata: !_vm.supplier.moq,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Minimum Order Quantity\n                  "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "supplier-content__data-content supplier__slider",
                                      class: {
                                        supplier__nodata: !_vm.supplier.moq,
                                      },
                                    },
                                    [
                                      _vm.supplier.moq
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "supplier__slider-label",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.supplier.moq == 2000
                                                      ? "2000+"
                                                      : _vm.supplier.moq
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _c(
                                            "em",
                                            {
                                              staticClass:
                                                "supplier__slider-label",
                                              on: { click: _vm.setMOQ },
                                            },
                                            [
                                              _vm._v(
                                                " Select Minimum Order Quantity "
                                              ),
                                            ]
                                          ),
                                      _vm._v(" "),
                                      _c("vue-slider", {
                                        staticClass: "supplier__moq-slider",
                                        attrs: {
                                          value: _vm.supplier.moq || 1,
                                          piecewise: true,
                                          data: [
                                            1, 20, 50, 100, 150, 200, 300, 400,
                                            500, 600, 700, 800, 900, 1000, 1500,
                                            2000,
                                          ],
                                          width: "100%",
                                          tooltip: false,
                                          bgStyle: {
                                            backgroundColor:
                                              "rgba(55, 73, 94, 0.13)",
                                          },
                                          sliderStyle: {
                                            backgroundColor: "#fff",
                                            border: "2px solid #37495e",
                                          },
                                          processStyle: {
                                            backgroundColor:
                                              "rgba(55, 73, 94, 0.8)",
                                          },
                                        },
                                        on: { callback: _vm.watchMOQ },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "supplier-content__data-label",
                                      class: {
                                        supplier__nodata:
                                          !_vm.supplier.dailyProductionFrom ||
                                          !_vm.supplier.dailyProductionTo,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Daily Production (Pairs)\n                  "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "supplier-content__data-content supplier__slider",
                                      class: {
                                        supplier__nodata:
                                          !_vm.supplier.dailyProductionFrom ||
                                          !_vm.supplier.dailyProductionTo,
                                      },
                                    },
                                    [
                                      _vm.supplier.dailyProductionFrom ||
                                      _vm.supplier.dailyProductionTo
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "supplier__slider-label",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.supplier
                                                      .dailyProductionFrom
                                                  ) +
                                                    " - " +
                                                    _vm._s(
                                                      _vm.supplier
                                                        .dailyProductionTo
                                                    )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _c(
                                            "em",
                                            {
                                              staticClass:
                                                "supplier__slider-label",
                                              on: { click: _vm.setDP },
                                            },
                                            [
                                              _vm._v(
                                                " Select Daily Production "
                                              ),
                                            ]
                                          ),
                                      _vm._v(" "),
                                      _c("vue-slider", {
                                        staticClass: "supplier__moq-slider",
                                        attrs: {
                                          value: [
                                            _vm.supplier.dailyProductionFrom ||
                                              1,
                                            _vm.supplier.dailyProductionTo ||
                                              2000,
                                          ],
                                          piecewise: true,
                                          data: [
                                            1, 20, 50, 100, 150, 200, 250, 300,
                                            350, 400, 450, 500, 550, 600, 650,
                                            700, 800, 900, 1000, 1100, 1200,
                                            1500, 2000,
                                          ],
                                          width: "100%",
                                          tooltip: false,
                                          bgStyle: {
                                            backgroundColor:
                                              "rgba(55, 73, 94, 0.13)",
                                          },
                                          sliderStyle: {
                                            backgroundColor: "#fff",
                                            border: "2px solid #37495e",
                                          },
                                          processStyle: {
                                            backgroundColor:
                                              "rgba(55, 73, 94, 0.8)",
                                          },
                                        },
                                        on: { callback: _vm.watchDP },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "supplier-content__data-label",
                                      class: {
                                        supplier__nodata: !_vm.supplier.brands,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Brands producing\n                  "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "supplier-content__data-content",
                                      class: {
                                        supplier__nodata: !_vm.supplier.brands,
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: _vm.supplier.brands,
                                            expression: "supplier.brands",
                                            modifiers: { trim: true },
                                          },
                                        ],
                                        staticClass: "supplier__input",
                                        attrs: {
                                          type: "text",
                                          placeholder:
                                            "Click here to add information",
                                        },
                                        domProps: {
                                          value: _vm.supplier.brands,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.supplier,
                                              "brands",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "supplier-content__data-label",
                                      class: {
                                        supplier__nodata:
                                          !_vm.supplier
                                            .materialExpertiseLeather &&
                                          !_vm.supplier
                                            .materialExpertiseTextile &&
                                          !_vm.supplier
                                            .materialExpertiseSynthetic,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Material Expertise\n                  "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "supplier-content__data-content supplier__grid",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "supplier__grid-item supplier__grid-item--small",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "supplier__checkbox",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.supplier
                                                        .materialExpertiseLeather,
                                                    expression:
                                                      "supplier.materialExpertiseLeather",
                                                  },
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.supplier
                                                      .materialExpertiseLeather
                                                  )
                                                    ? _vm._i(
                                                        _vm.supplier
                                                          .materialExpertiseLeather,
                                                        null
                                                      ) > -1
                                                    : _vm.supplier
                                                        .materialExpertiseLeather,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.supplier
                                                          .materialExpertiseLeather,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.supplier,
                                                            "materialExpertiseLeather",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.supplier,
                                                            "materialExpertiseLeather",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.supplier,
                                                        "materialExpertiseLeather",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("Leather")]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "supplier__grid-item supplier__grid-item--small",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "supplier__checkbox",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.supplier
                                                        .materialExpertiseTextile,
                                                    expression:
                                                      "supplier.materialExpertiseTextile",
                                                  },
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.supplier
                                                      .materialExpertiseTextile
                                                  )
                                                    ? _vm._i(
                                                        _vm.supplier
                                                          .materialExpertiseTextile,
                                                        null
                                                      ) > -1
                                                    : _vm.supplier
                                                        .materialExpertiseTextile,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.supplier
                                                          .materialExpertiseTextile,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.supplier,
                                                            "materialExpertiseTextile",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.supplier,
                                                            "materialExpertiseTextile",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.supplier,
                                                        "materialExpertiseTextile",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("Textile")]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "supplier__grid-item supplier__grid-item--small",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "supplier__checkbox",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.supplier
                                                        .materialExpertiseSynthetic,
                                                    expression:
                                                      "supplier.materialExpertiseSynthetic",
                                                  },
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.supplier
                                                      .materialExpertiseSynthetic
                                                  )
                                                    ? _vm._i(
                                                        _vm.supplier
                                                          .materialExpertiseSynthetic,
                                                        null
                                                      ) > -1
                                                    : _vm.supplier
                                                        .materialExpertiseSynthetic,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.supplier
                                                          .materialExpertiseSynthetic,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.supplier,
                                                            "materialExpertiseSynthetic",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.supplier,
                                                            "materialExpertiseSynthetic",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.supplier,
                                                        "materialExpertiseSynthetic",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("Synthetic")]),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "supplier-content__data-label",
                                      class: {
                                        supplier__nodata:
                                          !_vm.supplier.sizeRangeFrom ||
                                          !_vm.supplier.sizeRangeTo,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Size Range\n                  "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "supplier-content__data-content supplier__slider",
                                      class: {
                                        supplier__nodata:
                                          !_vm.supplier.sizeRangeFrom ||
                                          !_vm.supplier.sizeRangeTo,
                                      },
                                    },
                                    [
                                      _vm.supplier.sizeRangeFrom ||
                                      _vm.supplier.sizeRangeTo
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "supplier__slider-label",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.supplier.sizeRangeFrom
                                                  ) +
                                                    " - " +
                                                    _vm._s(
                                                      _vm.supplier.sizeRangeTo
                                                    )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _c(
                                            "em",
                                            {
                                              staticClass:
                                                "supplier__slider-label",
                                              on: { click: _vm.setSizeRange },
                                            },
                                            [_vm._v(" Select Size Range ")]
                                          ),
                                      _vm._v(" "),
                                      _c("vue-slider", {
                                        staticClass: "supplier__slider-slider",
                                        attrs: {
                                          value: [
                                            _vm.supplier.sizeRangeFrom || 11,
                                            _vm.supplier.sizeRangeTo || 55,
                                          ],
                                          min: 11,
                                          max: 55,
                                          width: "100%",
                                          tooltip: false,
                                          bgStyle: {
                                            backgroundColor:
                                              "rgba(55, 73, 94, 0.13)",
                                          },
                                          sliderStyle: {
                                            backgroundColor: "#fff",
                                            border: "2px solid #37495e",
                                          },
                                          processStyle: {
                                            backgroundColor:
                                              "rgba(55, 73, 94, 0.8)",
                                          },
                                        },
                                        on: { callback: _vm.watchSizeRange },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.guide &&
                      _vm.supplier.category != null &&
                      _vm.currentGuideStep == 8
                        ? _c("TourTooltip", {
                            attrs: {
                              title: "Add Information About Company",
                              message:
                                "This is an opportunity to specify what kind of business you are looking for but also to promote yourself.",
                              currentStep: _vm.currentStep,
                              guideSteps: _vm.guideSteps,
                              position: "top",
                              arrow: "left",
                            },
                            on: {
                              close: _vm.guideClose,
                              next: _vm.guideNext,
                              back: _vm.guideBack,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("h2", { attrs: { id: "customers" } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.supplier.category == 0
                            ? "Component suppliers working with"
                            : "Footwear suppliers working with"
                        ) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "supplier-content__data" }, [
                    _c("div", { staticClass: "customers-search" }, [
                      _c("label", { staticClass: "customers-search__form" }, [
                        _c("i", {
                          staticClass: "customers-search__icon",
                          domProps: { innerHTML: _vm._s(_vm.svg.searchIcon) },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.searchCustomer,
                              expression: "searchCustomer",
                              modifiers: { trim: true },
                            },
                          ],
                          ref: "customerAutocomplete",
                          staticClass:
                            "customers-search__input supplier__input",
                          attrs: {
                            type: "text",
                            placeholder: "Search for suppliers",
                            autocomplete: "off",
                          },
                          domProps: { value: _vm.searchCustomer },
                          on: {
                            focus: _vm.initCustomersAutocomplete,
                            blur: [
                              _vm.destroyCustomersAutocomplete,
                              function ($event) {
                                return _vm.$forceUpdate()
                              },
                            ],
                            keydown: _vm.customersHandleKeydown,
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.searchCustomer = $event.target.value.trim()
                              },
                              function ($event) {
                                return _vm.searchCustomers(
                                  _vm.supplier.category == 0 ? 1 : 0
                                )
                              },
                            ],
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _vm.customersAutcompleteVisible && _vm.searchCustomer
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "customers-search__results pac-container",
                            },
                            [
                              _vm._l(
                                _vm.customersSearchResults,
                                function (customer, i) {
                                  return _c(
                                    "div",
                                    {
                                      key: "customerSearch" + i,
                                      staticClass: "pac-item",
                                      class: {
                                        "pac-item-selected":
                                          i === _vm.selectedCustomerIndex,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.selectCustomer(customer)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "pac-item-query" },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(customer.name) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "pac-item",
                                  class: {
                                    "pac-item-selected":
                                      _vm.selectedCustomerIndex ==
                                      _vm.customersSearchResults.length,
                                  },
                                  on: { click: _vm.openCustomerModal },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "pac-item-query customers-search__add",
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "customers-search__add-icon",
                                      }),
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.supplier.category == 0
                                              ? "Add component supplier"
                                              : "Add footwear supplier"
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm.customers
                      ? _c(
                          "ul",
                          { staticClass: "supplier__customers" },
                          _vm._l(_vm.customers, function (customer, i) {
                            return _c(
                              "li",
                              { key: i, staticClass: "supplier__customer" },
                              [
                                _c("div", {
                                  staticClass: "supplier__customer-img",
                                  style: [
                                    customer.coverPhoto
                                      ? {
                                          backgroundImage:
                                            'url("' +
                                            _vm.imagePath(
                                              "images/" + customer.coverPhoto,
                                              1920,
                                              500
                                            ) +
                                            '")',
                                        }
                                      : { background: "#f9f9f9" },
                                  ],
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "supplier__customer-info" },
                                  [
                                    _c(
                                      "strong",
                                      {
                                        staticClass: "supplier__customer-title",
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(customer.name) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("button", {
                                  staticClass: "supplier-delete",
                                  attrs: { title: "Remove item" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeCustomer(i)
                                    },
                                  },
                                }),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "guideItem10",
                      staticClass: "guide-item",
                      class: {
                        "guide-item--active":
                          _vm.guide &&
                          _vm.supplier.category != null &&
                          _vm.currentGuideStep == 10,
                      },
                    },
                    [
                      _c(
                        "div",
                        { class: { "guide-item__content": _vm.guide } },
                        [
                          _c(
                            "h2",
                            {
                              class: {
                                supplier__nodata:
                                  !_vm.supplier.gallery ||
                                  !_vm.supplier.gallery.length,
                              },
                              attrs: { id: "gallery" },
                            },
                            [
                              _vm._v(
                                "\n                  Gallery\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "section",
                            { staticClass: "supplier__gallery" },
                            [
                              _vm._l(_vm.supplier.gallery, function (item, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass: "supplier__gallery-item",
                                  },
                                  [
                                    item.type == "img"
                                      ? _c("img", {
                                          staticClass: "supplier__gallery-img",
                                          attrs: {
                                            src:
                                              _vm.$blobUrl +
                                              "/images/" +
                                              item.url,
                                            alt: "",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.type == "video"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "supplier__gallery-video supplier__gallery-video",
                                          },
                                          [
                                            _c("iframe", {
                                              attrs: {
                                                src: item.url,
                                                frameborder: "0",
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("ContentEditable", {
                                      staticClass: "supplier__gallery-caption",
                                      attrs: {
                                        content: item.caption,
                                        "data-placeholder":
                                          "Type caption for " +
                                          (item.type == "img"
                                            ? "image"
                                            : "video") +
                                          " (optional)",
                                      },
                                      on: {
                                        update: function ($event) {
                                          item.caption = $event
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("button", {
                                      staticClass: "supplier-delete",
                                      attrs: { title: "Remove item" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.supplier.gallery.splice(
                                            i,
                                            1
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              _vm._v(" "),
                              _c(
                                "footer",
                                { staticClass: "supplier__gallery-footer" },
                                [
                                  _vm.galleryImageLoading
                                    ? _c("Progress", {
                                        attrs: {
                                          percentage:
                                            _vm.galleryImageLoadingPercentage,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.addVideoForm
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "supplier__gallery-buttons",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "supplier__gallery-button",
                                            },
                                            [
                                              _c("input", {
                                                attrs: {
                                                  type: "file",
                                                  name: "galleryImage",
                                                  accept: ".jpg, .png",
                                                  id: "newGalleryImage",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.readURL(
                                                      $event.target
                                                    )
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("i", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.svg.imgIcon
                                                  ),
                                                },
                                              }),
                                              _vm._v(
                                                "\n                        Add image\n                      "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "supplier__gallery-button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openAddVideoForm()
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.svg.videoIcon
                                                  ),
                                                },
                                              }),
                                              _vm._v(
                                                "\n                        Add video\n                      "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.addVideoForm
                                    ? _c(
                                        "form",
                                        {
                                          staticClass: "supplier__add-video",
                                          attrs: {
                                            action: "#",
                                            method: "post",
                                          },
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              return _vm.addVideo($event)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "supplier__add-video-wrap",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value: _vm.addVideoUrl,
                                                    expression: "addVideoUrl",
                                                    modifiers: { trim: true },
                                                  },
                                                ],
                                                ref: "addVideoInput",
                                                staticClass:
                                                  "supplier__add-video-input",
                                                attrs: {
                                                  type: "text",
                                                  placeholder:
                                                    "Paste a YouTube or Vimeo link",
                                                },
                                                domProps: {
                                                  value: _vm.addVideoUrl,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.addVideoUrl =
                                                      $event.target.value.trim()
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "supplier__add-video-button",
                                                  attrs: { type: "submit" },
                                                },
                                                [_vm._v("Add Video")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "supplier__add-video-button",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.closeAddVideoForm()
                                                    },
                                                  },
                                                },
                                                [_vm._v("Cancel")]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.addVideoUrlError
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "supplier__add-video-error",
                                                },
                                                [
                                                  _vm._v(
                                                    "Video link is not valid"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.guide &&
                      _vm.supplier.category != null &&
                      _vm.currentGuideStep == 10
                        ? _c("TourTooltip", {
                            attrs: {
                              title: "Add Images And Videos",
                              message:
                                "Share what kind of products you manage but also the production facilities. Buyers want to see the conditions the products will be produced in. Why not a picture of the team taking care of customer relations to give a face to relate to.",
                              currentStep: _vm.currentStep,
                              guideSteps: _vm.guideSteps,
                              position: "top",
                              arrow: "left",
                            },
                            on: {
                              close: _vm.guideClose,
                              next: _vm.guideNext,
                              back: _vm.guideBack,
                              save: _vm.guideSave,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "supplier-user-button",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.updateSupplier()
                      },
                    },
                  },
                  [_vm._v("Update Supplier")]
                ),
                _vm._v(" "),
                _c(
                  "section",
                  {
                    staticClass: "location-modal",
                    class: {
                      active: _vm.locationModal,
                      hidden: !_vm.locationMap && !_vm.$store.user.chineese,
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "location-modal__overlay",
                      on: { click: _vm.closeLocationModal },
                    }),
                    _vm._v(" "),
                    _c("button", {
                      staticClass: "location-modal__close",
                      on: { click: _vm.closeLocationModal },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "location-modal__box",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        !_vm.$store.user.chineese
                          ? _c("div", {
                              ref: "locationModalMap",
                              staticClass: "location-modal__map",
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$store.user.chineese && _vm.locationModal
                          ? _c(
                              "baidu-map",
                              {
                                ref: "locationModalMap",
                                staticClass: "location-modal__map",
                                attrs: {
                                  "scroll-wheel-zoom":
                                    _vm.baidu.scrollWheelZoom,
                                  center: _vm.baiduCenter,
                                  zoom: _vm.baidu.locationModal.zoom,
                                  ak: _vm.baidu.ak,
                                  mapStyle: _vm.baidu.mapStyle,
                                },
                                on: { click: _vm.locationModalMapClick },
                              },
                              [
                                _c("bm-navigation", {
                                  attrs: { anchor: "BMAP_ANCHOR_TOP_RIGHT" },
                                }),
                                _vm._v(" "),
                                _vm.baidu.locationModal.coordinates
                                  ? _c("bm-marker", {
                                      attrs: {
                                        position:
                                          _vm.baidu.locationModal.coordinates,
                                        icon: _vm.baidu.markerStyle,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.locationNewPlace
                          ? _c(
                              "div",
                              { staticClass: "location-modal__controls" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "location-modal__button location-modal__button--white",
                                    on: { click: _vm.closeLocationModal },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Cancel\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "location-modal__button location-modal__button--blue",
                                    on: { click: _vm.saveNewLocationPlace },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Save\n                "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              { staticClass: "location-modal__tooltip" },
                              [_vm._v("Click on the map to set new location")]
                            ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.customerModal
                  ? _c("section", { staticClass: "form-popup" }, [
                      _c("div", { staticClass: "form-popup__container" }, [
                        _c("div", { staticClass: "form-popup__content" }, [
                          _c("button", {
                            staticClass: "form-popup__close",
                            on: { click: _vm.closeCustomerModal },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "old-form" }, [
                            _c("h2", { staticClass: "old-form__heading" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.supplier.category == 0
                                      ? "Add Component Supplier"
                                      : "Add Footwear Supplier"
                                  ) +
                                  "\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addCustomer(
                                      _vm.supplier.category == 0 ? 1 : 0
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "old-form__control" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "old-form__label",
                                        class: {
                                          "old-form__label--filled":
                                            _vm.customer.title.length,
                                        },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.trim",
                                              value: _vm.customer.title,
                                              expression: "customer.title",
                                              modifiers: { trim: true },
                                            },
                                          ],
                                          staticClass: "old-form__input",
                                          attrs: {
                                            type: "text",
                                            placeholder: "Company Name",
                                            required: "",
                                          },
                                          domProps: {
                                            value: _vm.customer.title,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.customer,
                                                "title",
                                                $event.target.value.trim()
                                              )
                                            },
                                            blur: function ($event) {
                                              return _vm.$forceUpdate()
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "old-form__placeholder",
                                          },
                                          [_vm._v("Company Name")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.customer.titleError
                                      ? _c(
                                          "span",
                                          { staticClass: "old-form__error" },
                                          [
                                            _vm._v(
                                              "Company with this name already exists"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "old-form__control" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "old-form__label",
                                        class: {
                                          "old-form__label--filled":
                                            _vm.customer.email,
                                        },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.trim",
                                              value: _vm.customer.email,
                                              expression: "customer.email",
                                              modifiers: { trim: true },
                                            },
                                          ],
                                          staticClass: "old-form__input",
                                          attrs: {
                                            type: "email",
                                            placeholder: "Company Email",
                                            required: "",
                                          },
                                          domProps: {
                                            value: _vm.customer.email,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.customer,
                                                "email",
                                                $event.target.value.trim()
                                              )
                                            },
                                            blur: function ($event) {
                                              return _vm.$forceUpdate()
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "old-form__placeholder",
                                          },
                                          [_vm._v("Company Email")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.customer.emailError
                                      ? _c(
                                          "span",
                                          { staticClass: "old-form__error" },
                                          [
                                            _vm._v(
                                              "Company with this name already exists"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._m(0),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "loader",
                            class: { "is-visible": _vm.addingCustomer },
                          }),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "section",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.coverPhotoCrop,
                        expression: "coverPhotoCrop",
                      },
                    ],
                    staticClass: "form-popup croppa-popup",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "form-popup__container",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "form-popup__content" }, [
                          _c(
                            "div",
                            { staticClass: "croppa-popup__wrap" },
                            [
                              _c("croppa", {
                                attrs: {
                                  accept: ".jpg,.jpeg,.png",
                                  width: _vm.coverPhotoCropWidth,
                                  height: _vm.coverPhotoCropHeight,
                                  quality: _vm.coverPhotoCropQuality,
                                  "prevent-white-space": true,
                                  "show-remove-button": false,
                                  "placeholder-color": "#333",
                                  "placeholder-font-size": 16,
                                  "canvas-color": "transparent",
                                },
                                on: {
                                  "new-image-drawn": function ($event) {
                                    _vm.coverPhotoCrop = true
                                  },
                                  "file-choose": _vm.handleCroppaChange,
                                },
                                model: {
                                  value: _vm.coverPhotoCroppa,
                                  callback: function ($$v) {
                                    _vm.coverPhotoCroppa = $$v
                                  },
                                  expression: "coverPhotoCroppa",
                                },
                              }),
                              _vm._v(" "),
                              _vm._m(1),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("button", {
                            staticClass: "form-popup__close",
                            on: {
                              click: function ($event) {
                                _vm.coverPhotoCroppa.remove()
                                _vm.coverPhotoCrop = false
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "croppa-popup__footer" }, [
                            _c(
                              "div",
                              { staticClass: "croppa-popup__footer-left" },
                              [
                                _c("button", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.svg.plusIcon),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.coverPhotoCroppa.zoomIn()
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("button", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.svg.minusIcon),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.coverPhotoCroppa.zoomOut()
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "croppa-popup__footer-right" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "old-form__button old-form__button--inline old-form__button--outlined",
                                    on: {
                                      click: function ($event) {
                                        _vm.coverPhotoCroppa.remove()
                                        _vm.coverPhotoCrop = false
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      Cancel\n                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "old-form__button old-form__button--inline",
                                    on: {
                                      click: function ($event) {
                                        return _vm.uploadCover(
                                          "image/jpeg",
                                          0.8
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      Save Changes\n                    "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "old-form__control" }, [
      _c(
        "button",
        { staticClass: "old-form__button", attrs: { type: "submit" } },
        [_vm._v("Add company")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "croppa-popup__fixed-text" }, [
      _c("i", [_vm._v("☰")]),
      _vm._v(" Drag to Reposition Cover"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }