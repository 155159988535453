<template>
  <div class="progress">
    <div class="progress-wrap">
      <svg class="progress-circle" width="70px" height="70px" xmlns="http://www.w3.org/2000/svg">
        <circle class="progress-circle-back" cx="35" cy="35" r="33"></circle>
        <circle
          class="progress-circle-progress"
          cx="35"
          cy="35"
          r="33"
          :stroke-dasharray="`${progress}, 207.35`"
        ></circle>
      </svg>
      <div class="progress-text">{{ percentage == 100 ? 'Loading' : `${percentage}%` }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['percentage'],
  computed: {
    progress() {
      return (+this.percentage * 207.35) / 100;
    },
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';

.progress {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba($c-light, 0.95);
  z-index: 3;
  &-wrap {
    position: relative;
    width: math.div(7rem, 1.6);
    height: math.div(7rem, 1.6);
  }
  &-circle {
    transform: rotate(-90deg);
    &-back {
      fill: none;
      stroke: rgba($c-dark, 0.3);
      stroke-width: 2px;
    }
    &-progress {
      fill: none;
      stroke: $c-blue;
      stroke-width: 2px;
      stroke-dashoffset: 0;
    }
  }
  &-text {
    width: 100%;
    position: absolute;
    top: 50%;
    text-align: center;
    font-size: math.div(1rem, 1.6);
    line-height: math.div(2rem, 1.6);
    margin-top: math.div(-1rem, 1.6);
    color: rgba($c-dark, 0.6);
    text-transform: uppercase;
  }
}
</style>
