var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    attrs: { contenteditable: "true" },
    on: {
      keydown: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        ) {
          return null
        }
        $event.preventDefault()
      },
      input: _vm.update,
      paste: _vm.paste,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }