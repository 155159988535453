<template>
  <div>
    <div v-if="supplier.status === 0" class="supplier__na">
      <p>Page not found</p>
    </div>
    <div class="supplier__na" v-else-if="!userHasPermission">
      <p>Sorry, you don't have permission to edit this supplier</p>
    </div>
    <section class="supplier" ref="supplier" v-else>
      <div class="supplier__wrap">
        <div v-if="supplier.category == null" class="supplier-category">
          <div class="supplier-category__box">
            <strong class="supplier-category__title">What do you produce?</strong>
            <p class="supplier-category__text">
              Select whether you produce footwear or if you supply components (such as materials) needed by footwear
              suppliers.
            </p>
            <div class="supplier-category__buttons">
              <label class="supplier-category__button">
                <input type="radio" value="0" v-model="supplier.category" />
                Footwear
              </label>
              <label class="supplier-category__button">
                <input type="radio" value="1" v-model="supplier.category" />
                Components
              </label>
            </div>
          </div>
        </div>
        <div
          class="guide-item"
          ref="guideItem9"
          :class="{ 'guide-item--active': guide && supplier.category != null && currentGuideStep == 9 }"
        >
          <header
            class="supplier__header"
            :class="{ 'supplier__header--empty': !coverPhotoData, 'guide-item__content': guide }"
            :style="[
              coverPhotoData
                ? { backgroundImage: `url(&quot;${coverPhotoData}&quot;)` }
                : {
                    background: 'radial-gradient(ellipse at center, rgba(65,152,208,0.2) 0%,rgba(65,152,208,0.1) 100%)',
                  },
            ]"
            ref="header"
          >
            <span class="supplier__file supplier__header-button" @click="coverPhotoCroppa.chooseFile()">
              {{ supplier.coverPhoto ? 'Change cover photo' : 'Choose cover photo' }}
            </span>
            <div class="loader" :class="{ 'is-visible': coverPhotoLoading }"></div>
          </header>
          <TourTooltip
            v-if="guide && supplier.category != null && currentGuideStep == 9"
            title="Add Cover Photo"
            message="This will be the first impression of your business. Spend some time to select a photo which captures your business in a good way. It will pay off."
            :currentStep="currentStep"
            :guideSteps="guideSteps"
            position="bottom"
            arrow="center"
            @close="guideClose"
            @next="guideNext"
            @back="guideBack"
          >
          </TourTooltip>
        </div>
        <div class="supplier__container">
          <nav class="supplier__nav">
            <ul class="supplier__nav-list" ref="nav">
              <li class="supplier__nav-item">
                <a class="supplier__nav-link" href="#" v-scroll-to="{ el: '#overview', offset: -115 }" @click.prevent>
                  <i class="supplier__nav-icon" v-html="svg.overviewIcon"></i>
                  Overview
                </a>
              </li>
              <li class="supplier__nav-item">
                <a class="supplier__nav-link" href="#" v-scroll-to="{ el: '#production', offset: -90 }" @click.prevent>
                  <i class="supplier__nav-icon" v-html="svg.productionIcon"></i>
                  Production
                </a>
              </li>
              <li class="supplier__nav-item">
                <a class="supplier__nav-link" href="#" v-scroll-to="{ el: '#info', offset: -90 }" @click.prevent>
                  <i class="supplier__nav-icon" v-html="svg.companyIcon"></i>
                  Company information
                </a>
              </li>
              <li class="supplier__nav-item">
                <a class="supplier__nav-link" href="#" v-scroll-to="{ el: '#customers', offset: -90 }" @click.prevent>
                  <i class="supplier__nav-icon" v-html="svg.customersIcon"></i>
                  {{ supplier.category == 0 ? 'Component suppliers' : 'Footwear suppliers' }}
                </a>
              </li>
              <li class="supplier__nav-item">
                <a class="supplier__nav-link" href="#" v-scroll-to="{ el: '#gallery', offset: -90 }" @click.prevent>
                  <i class="supplier__nav-icon" v-html="svg.galleryIcon"></i>
                  Gallery
                </a>
              </li>
            </ul>
          </nav>
          <section class="supplier__info">
            <div class="supplier-content">
              <div
                class="guide-item"
                ref="guideItem1"
                :class="{ 'guide-item--active': guide && supplier.category != null && currentGuideStep == 1 }"
              >
                <ContentEditable
                  id="overview"
                  class="supplier__name"
                  :class="{ 'guide-item__content': guide }"
                  data-placeholder="Company Name"
                  :content="supplier.title"
                  @update="supplier.title = $event"
                  data-field="companyName"
                >
                </ContentEditable>
                <div
                  v-if="titleUniqueError"
                  :class="{ supplier__error: true, 'guide-item__content': guide }"
                  data-error="companyName"
                >
                  Company with this name already exists
                </div>
                <div
                  v-if="guide && guideIsStarted && titleError"
                  class="supplier__error"
                  :class="{ 'guide-item__content': guide }"
                  data-error="companyName"
                >
                  Company name is required
                </div>
                <TourTooltip
                  v-if="guide && supplier.category != null && currentGuideStep == 1"
                  title="Enter and Verify Company Name"
                  message="Your company name will be among the first impressions, it will be visible in the search."
                  :currentStep="currentStep"
                  :guideSteps="guideSteps"
                  position="bottom"
                  arrow="center"
                  @close="guideClose"
                  @next="guideNext"
                >
                </TourTooltip>
              </div>

              <div v-if="titleError && validate" class="supplier__error" data-error="companyName">
                Company name is required
              </div>

              <div
                class="supplier__overview supplier-content"
                @ready="onEditorReady($event)"
                v-quill:supplierOverview="editorOptions"
                v-model="supplier.overview"
              ></div>

              <div class="supplier__contacts">
                <div
                  v-if="!$store.user.chineese"
                  class="supplier__map"
                  :class="{ 'supplier__map--guide': currentGuideStep == 2 }"
                  ref="supplierMap"
                ></div>
                <baidu-map
                  v-if="$store.user.chineese"
                  class="supplier__map"
                  :class="{ 'supplier__map--guide': currentGuideStep == 2 }"
                  ref="supplierMap"
                  :center="baiduCenter"
                  :scroll-wheel-zoom="baidu.scrollWheelZoom"
                  :zoom="baidu.zoom"
                  :ak="baidu.ak"
                  :mapStyle="baidu.mapStyle"
                  @ready="baiduReady"
                  @load="baiduLoad"
                >
                  <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                  <bm-marker v-if="baiduCoordinates" :position="baiduCoordinates" :icon="baidu.markerStyle">
                  </bm-marker>
                </baidu-map>
                <div class="supplier__contacts-info">
                  <div
                    class="guide-item"
                    ref="guideItem2"
                    :class="{ 'guide-item--active': guide && supplier.category != null && currentGuideStep == 2 }"
                  >
                    <div class="supplier__search" :class="{ 'guide-item__content': guide }">
                      <a href="#" @click.prevent="openLocationModal">Click here</a> to select location by clicking on
                      map
                    </div>
                    <div
                      class="supplier-content__data supplier__contacts-address"
                      :class="{ 'guide-item__content': guide }"
                    >
                      <div class="supplier-content__data-label" :class="{ supplier__nodata: !supplier.fullAddress }">
                        Address
                      </div>
                      <div class="supplier-content__data-content" :class="{ supplier__nodata: !supplier.fullAddress }">
                        <input
                          type="text"
                          class="supplier__input"
                          ref="addressAutocomplete"
                          v-model.trim="supplier.fullAddress"
                          placeholder="Enter address"
                          data-field="address"
                        />
                      </div>
                      <div v-if="addressError && validate" class="supplier__error" data-error="address">
                        Address is required
                      </div>
                      <div class="supplier-content__data-label" :class="{ supplier__nodata: !supplier.city }">City</div>
                      <div class="supplier-content__data-content" :class="{ supplier__nodata: !supplier.city }">
                        <input
                          type="text"
                          class="supplier__input"
                          ref="cityAutocomplete"
                          v-model.trim="supplier.city"
                          placeholder="Enter city"
                          data-field="city"
                        />
                      </div>
                      <div class="supplier-content__data-label" :class="{ supplier__nodata: !searchCountry }">
                        Country
                      </div>
                      <div class="supplier-content__data-content" :class="{ supplier__nodata: !searchCountry }">
                        <input
                          type="text"
                          class="supplier__input"
                          ref="countryAutocomplete"
                          v-model.trim="searchCountry"
                          placeholder="Select country"
                          data-field="country"
                          autocomplete="off"
                          @focus="initCountriesAutocomplete"
                          @blur="destroyCountriesAutocomplete"
                          @keydown="countriesHandleKeydown"
                          @input="filterCountries"
                        />
                        <div
                          v-if="filteredCountries && filteredCountries.length && countriesAutcompleteVisible"
                          class="pac-container"
                        >
                          <div
                            class="pac-item"
                            v-for="(country, i) in filteredCountries"
                            :key="`country${i}`"
                            :class="{ 'pac-item-selected': i === selectedCountryIndex }"
                            @click.stop="selectCountry(country)"
                          >
                            <span class="pac-item-query">
                              {{ country }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="(countryError || !searchCountry) && validate"
                        class="supplier__error"
                        data-error="country"
                      >
                        Country is required
                      </div>
                      <div
                        class="supplier-content__data-label"
                        :class="{
                          supplier__nodata: !($store.user.chineese ? baidu.coordinatesAsText : supplier.coordinates),
                        }"
                      >
                        Coordinates
                      </div>
                      <div
                        class="supplier-content__data-content"
                        :class="{
                          supplier__nodata: !($store.user.chineese ? baidu.coordinatesAsText : supplier.coordinates),
                        }"
                      >
                        <input
                          v-if="!$store.user.chineese"
                          type="text"
                          class="supplier__input"
                          ref="coordinates"
                          v-model.trim="supplier.coordinates"
                          placeholder="Enter coordinates"
                          data-field="coordinates"
                        />
                        <input
                          v-if="$store.user.chineese"
                          type="text"
                          class="supplier__input"
                          ref="coordinates"
                          v-model.trim="baidu.coordinatesAsText"
                          placeholder="Enter coordinates"
                          data-field="coordinates"
                        />
                      </div>
                      <div v-if="coordinatesError && validate" class="supplier__error" data-error="coordinates">
                        Coordinates are not correct
                      </div>
                    </div>
                    <TourTooltip
                      v-if="guide && supplier.category != null && currentGuideStep == 2"
                      title="Enter Address"
                      message="There are 3 ways to enter your adrress: Directly in the text field, by Entering coordinates or just Click on the map."
                      :currentStep="currentStep"
                      :guideSteps="guideSteps"
                      position="bottom"
                      arrow="left"
                      @close="guideClose"
                      @next="guideNext"
                      @back="guideBack"
                    >
                    </TourTooltip>
                  </div>
                  <div class="supplier__contacts-lists">
                    <div
                      class="guide-item supplier__contacts-list"
                      ref="guideItem3"
                      :class="{ 'guide-item--active': guide && supplier.category != null && currentGuideStep == 3 }"
                    >
                      <ul :class="{ 'guide-item__content': guide }">
                        <li class="supplier__contacts-item" :class="{ supplier__nodata: !supplier.web }">
                          <span class="supplier__contacts-link">
                            <i class="supplier__contacts-icon" v-html="svg.siteIcon"></i>
                            <input
                              type="url"
                              class="supplier__input"
                              v-model.trim="supplier.web"
                              placeholder="Enter homepage"
                              data-field="homepage"
                            />
                          </span>
                        </li>
                        <li v-if="homepageError && validate" class="supplier__error" data-error="homepage">
                          Url is not valid
                        </li>
                        <li class="supplier__contacts-item" :class="{ supplier__nodata: !supplier.email }">
                          <span class="supplier__contacts-link">
                            <i class="supplier__contacts-icon" v-html="svg.emailIcon"></i>
                            <input
                              type="email"
                              class="supplier__input"
                              v-model.trim="supplier.email"
                              placeholder="Enter email address"
                              data-field="email"
                            />
                          </span>
                        </li>
                        <li v-if="emailError && validate" class="supplier__error" data-error="email">
                          Email is not valid
                        </li>
                        <li class="supplier__contacts-item" :class="{ supplier__nodata: !supplier.phone }">
                          <span class="supplier__contacts-link">
                            <i class="supplier__contacts-icon" v-html="svg.phoneIcon"></i>
                            <input
                              type="tel"
                              class="supplier__input"
                              v-model.trim="supplier.phone"
                              placeholder="Enter phone number"
                              data-field="phone"
                            />
                          </span>
                        </li>
                        <li v-if="phoneError && validate" class="supplier__error" data-error="phone">
                          Phone number is not valid
                        </li>
                        <li class="supplier__contacts-item" :class="{ supplier__nodata: !supplier.whatsApp }">
                          <span class="supplier__contacts-link">
                            <i class="supplier__contacts-icon" v-html="svg.whatsApp"></i>
                            <input
                              type="text"
                              class="supplier__input"
                              v-model.trim="supplier.whatsApp"
                              placeholder="Enter WhatsApp"
                            />
                          </span>
                        </li>
                        <li class="supplier__contacts-item" :class="{ supplier__nodata: !supplier.weChat }">
                          <span class="supplier__contacts-link">
                            <i class="supplier__contacts-icon" v-html="svg.weChat"></i>
                            <input
                              type="text"
                              class="supplier__input"
                              v-model.trim="supplier.weChat"
                              placeholder="Enter WeChat"
                            />
                          </span>
                        </li>
                        <li class="supplier__contacts-item" :class="{ supplier__nodata: !supplier.skype }">
                          <span class="supplier__contacts-link">
                            <i class="supplier__contacts-icon" v-html="svg.skype"></i>
                            <input
                              type="text"
                              class="supplier__input"
                              v-model.trim="supplier.skype"
                              placeholder="Enter Skype"
                            />
                          </span>
                        </li>
                      </ul>
                      <TourTooltip
                        v-if="guide && supplier.category != null && currentGuideStep == 3"
                        title="Enter Contact Information"
                        message="Make yourself available for contact by entering contact information. The more details the easier to contact."
                        :currentStep="currentStep"
                        :guideSteps="guideSteps"
                        position="bottom"
                        arrow="left"
                        @close="guideClose"
                        @next="guideNext"
                        @back="guideBack"
                      >
                      </TourTooltip>
                    </div>
                    <ul class="supplier__contacts-list">
                      <li class="supplier__contacts-item" :class="{ supplier__nodata: !supplier.linkedIn }">
                        <span class="supplier__contacts-link">
                          <i class="supplier__contacts-icon" v-html="svg.linkedIn"></i>
                          <input
                            type="url"
                            class="supplier__input"
                            v-model.trim="supplier.linkedIn"
                            placeholder="Add LinkedIn profile"
                            data-field="linkedIn"
                          />
                        </span>
                      </li>
                      <li v-if="linkedInError && validate" class="supplier__error" data-error="linkedIn">
                        Url is not valid
                      </li>
                      <li class="supplier__contacts-item" :class="{ supplier__nodata: !supplier.facebook }">
                        <span class="supplier__contacts-link">
                          <i class="supplier__contacts-icon" v-html="svg.facebook"></i>
                          <input
                            type="url"
                            class="supplier__input"
                            v-model.trim="supplier.facebook"
                            placeholder="Add Facebook profile"
                            data-field="facebook"
                          />
                        </span>
                      </li>
                      <li v-if="facebookError && validate" class="supplier__error" data-error="facebook">
                        Url is not valid
                      </li>
                      <li
                        class="supplier__contacts-item"
                        :class="{
                          'supplier__contacts-item--hidden': !showSocialLinksOnMobile,
                          supplier__nodata: !supplier.instagram,
                        }"
                      >
                        <span class="supplier__contacts-link">
                          <i class="supplier__contacts-icon" v-html="svg.instagram"></i>
                          <input
                            type="url"
                            class="supplier__input"
                            v-model.trim="supplier.instagram"
                            placeholder="Add Instagram profile"
                            data-field="instagram"
                          />
                        </span>
                      </li>
                      <li v-if="instagramError && validate" class="supplier__error" data-error="instagram">
                        Url is not valid
                      </li>
                      <li
                        class="supplier__contacts-item"
                        :class="{
                          'supplier__contacts-item--hidden': !showSocialLinksOnMobile,
                          supplier__nodata: !supplier.pinterest,
                        }"
                      >
                        <span class="supplier__contacts-link">
                          <i class="supplier__contacts-icon" v-html="svg.pinterest"></i>
                          <input
                            type="url"
                            class="supplier__input"
                            v-model.trim="supplier.pinterest"
                            placeholder="Add Pinterest profile"
                            data-field="pinterest"
                          />
                        </span>
                      </li>
                      <li v-if="pinterestError && validate" class="supplier__error" data-error="pinterest">
                        Url is not valid
                      </li>
                      <li
                        class="supplier__contacts-item"
                        :class="{
                          'supplier__contacts-item--hidden': !showSocialLinksOnMobile,
                          supplier__nodata: !supplier.twitter,
                        }"
                      >
                        <span class="supplier__contacts-link">
                          <i class="supplier__contacts-icon" v-html="svg.twitter"></i>
                          <input
                            type="url"
                            class="supplier__input"
                            v-model.trim="supplier.twitter"
                            placeholder="Add Twitter profile"
                            data-field="twitter"
                          />
                        </span>
                      </li>
                      <li v-if="twitterError && validate" class="supplier__error" data-error="twitter">
                        Url is not valid
                      </li>
                      <li
                        class="supplier__contacts-item"
                        :class="{
                          'supplier__contacts-item--hidden': !showSocialLinksOnMobile,
                          supplier__nodata: !supplier.googlePlus,
                        }"
                      >
                        <span class="supplier__contacts-link">
                          <i class="supplier__contacts-icon" v-html="svg.googlePlus"></i>
                          <input
                            type="url"
                            class="supplier__input"
                            v-model.trim="supplier.googlePlus"
                            placeholder="Add Google+ profile"
                            data-field="googlePlus"
                          />
                        </span>
                      </li>
                      <li v-if="googlePlusError && validate" class="supplier__error" data-error="googlePlus">
                        Url is not valid
                      </li>
                      <li
                        class="supplier__contacts-item"
                        :class="{
                          'supplier__contacts-item--hidden': !showSocialLinksOnMobile,
                          supplier__nodata: !supplier.youTube,
                        }"
                      >
                        <span class="supplier__contacts-link">
                          <i class="supplier__contacts-icon" v-html="svg.youtube"></i>
                          <input
                            type="url"
                            class="supplier__input"
                            v-model.trim="supplier.youTube"
                            placeholder="Add YouTube profile"
                            data-field="youtube"
                          />
                        </span>
                      </li>
                      <li v-if="youtubeError && validate" class="supplier__error" data-error="youtube">
                        Url is not valid
                      </li>
                      <li
                        class="supplier__contacts-item supplier__contacts-item-sm"
                        :class="{ 'supplier__contacts-item--hidden': showSocialLinksOnMobile }"
                      >
                        <a href="#" @click.prevent="showSocialLinksOnMobile = true">Show more</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <h2
                id="production"
                :class="{
                  supplier__nodata:
                    footwearTypesError &&
                    processError &&
                    genderError &&
                    supplier.lastingMethods &&
                    !supplier.lastingMethods.length &&
                    supplier.niche &&
                    !supplier.niche.length &&
                    supplier.capabilities &&
                    !supplier.capabilities.length &&
                    supplier.category == null,
                }"
              >
                Production
              </h2>

              <div class="supplier-content__data">
                <div
                  class="supplier-content__data-label"
                  :class="{ supplier__nodata: supplier.companyStructure == null }"
                >
                  Company Structure
                </div>
                <div class="supplier-content__data-content supplier__grid">
                  <div class="supplier__grid-item supplier__grid-item--small">
                    <label class="supplier__radio">
                      <input type="radio" value="0" v-model="supplier.companyStructure" />
                      <span>Manufacturer</span>
                    </label>
                  </div>
                  <div class="supplier__grid-item supplier__grid-item--small">
                    <label class="supplier__radio">
                      <input type="radio" value="1" v-model="supplier.companyStructure" />
                      <span>Agent</span>
                    </label>
                  </div>
                </div>

                <div class="supplier-content__data-label" :class="{ supplier__nodata: supplier.category == null }">
                  Producing
                </div>
                <div class="supplier-content__data-content supplier__grid">
                  <div class="supplier__grid-item supplier__grid-item--small">
                    <label class="supplier__radio">
                      <input type="radio" value="0" v-model="supplier.category" />
                      <span>Footwear</span>
                    </label>
                  </div>
                  <div class="supplier__grid-item supplier__grid-item--large">
                    <label class="supplier__radio">
                      <input type="radio" value="1" v-model="supplier.category" />
                      <span>Components & Services</span>
                    </label>
                  </div>
                </div>
              </div>

              <div v-if="supplier.category == 0" class="supplier-content__data">
                <div
                  class="guide-item"
                  ref="guideItem4"
                  :class="{ 'guide-item--active': guide && supplier.category != null && currentGuideStep == 4 }"
                >
                  <div
                    class="supplier-content__data"
                    :class="{ 'guide-item__content': guide }"
                    data-field="footwearTypes"
                  >
                    <div class="supplier-content__data-label" :class="{ supplier__nodata: footwearTypesError }">
                      Footwear Types
                    </div>
                    <div class="supplier-content__data-content supplier__grid">
                      <div
                        class="supplier__grid-item supplier__grid-item--large"
                        v-for="(article, i) in footwearTypesArticles"
                        :key="i"
                      >
                        <label class="supplier__checkbox">
                          <input type="checkbox" :value="article.id" v-model="selectedFootwearTypes" />
                          <span>{{ article.title }}</span>
                        </label>
                      </div>
                      <div v-if="footwearTypesError && validate" class="supplier__error" data-error="footwearTypes">
                        Footwear types are required
                      </div>
                    </div>
                  </div>
                  <TourTooltip
                    v-if="guide && supplier.category != null && currentGuideStep == 4"
                    title="Select Footwear Types"
                    message="Which footwear types can you handle? Enter by pressing the check boxes."
                    :currentStep="currentStep"
                    :guideSteps="guideSteps"
                    position="bottom"
                    arrow="center"
                    @close="guideClose"
                    @next="guideNext"
                    @back="guideBack"
                  >
                  </TourTooltip>
                </div>

                <div
                  class="guide-item"
                  ref="guideItem5"
                  :class="{ 'guide-item--active': guide && supplier.category != null && currentGuideStep == 5 }"
                >
                  <div class="supplier-content__data" :class="{ 'guide-item__content': guide }" data-field="process">
                    <div class="supplier-content__data-label" :class="{ supplier__nodata: processError }">Process</div>
                    <div class="supplier-content__data-content supplier__grid">
                      <div
                        class="supplier__grid-item supplier__grid-item--large"
                        v-for="(article, i) in processesArticles"
                        :key="i"
                      >
                        <label class="supplier__checkbox">
                          <input type="checkbox" :value="article.id" v-model="selectedProcesses" />
                          <span>{{ article.title }}</span>
                        </label>
                      </div>
                      <div v-if="processError && validate" class="supplier__error" data-error="process">
                        Process is required
                      </div>
                    </div>
                  </div>
                  <TourTooltip
                    v-if="guide && supplier.category != null && currentGuideStep == 5"
                    title="Select Processes"
                    message="Enter by pressing the check boxes for the processes you can produce/handle."
                    :currentStep="currentStep"
                    :guideSteps="guideSteps"
                    position="bottom"
                    arrow="center"
                    @close="guideClose"
                    @next="guideNext"
                    @back="guideBack"
                  >
                  </TourTooltip>
                </div>

                <div
                  class="guide-item"
                  ref="guideItem6"
                  :class="{ 'guide-item--active': guide && supplier.category != null && currentGuideStep == 6 }"
                >
                  <div class="supplier-content__data" :class="{ 'guide-item__content': guide }" data-field="gender">
                    <div class="supplier-content__data-label" :class="{ supplier__nodata: genderError }">Gender</div>
                    <div class="supplier-content__data-content supplier__grid">
                      <div class="supplier__grid-item supplier__grid-item--large">
                        <label class="supplier__checkbox">
                          <input type="checkbox" v-model="supplier.men" />
                          <span>Men</span>
                        </label>
                      </div>
                      <div class="supplier__grid-item supplier__grid-item--large">
                        <label class="supplier__checkbox">
                          <input type="checkbox" v-model="supplier.ladies" />
                          <span>Ladies</span>
                        </label>
                      </div>
                      <div class="supplier__grid-item supplier__grid-item--large">
                        <label class="supplier__checkbox">
                          <input type="checkbox" v-model="supplier.kids" />
                          <span>Boys & Girls</span>
                        </label>
                      </div>
                      <div class="supplier__grid-item supplier__grid-item--large">
                        <label class="supplier__checkbox">
                          <input type="checkbox" v-model="supplier.babies" />
                          <span>Babies</span>
                        </label>
                      </div>
                      <div v-if="genderError && validate" class="supplier__error" data-error="gender">
                        Gender is required
                      </div>
                    </div>
                  </div>
                  <TourTooltip
                    v-if="guide && supplier.category != null && currentGuideStep == 6"
                    title="Select Gender"
                    message="Select which genders and age categories you can produce and/or handle."
                    :currentStep="currentStep"
                    :guideSteps="guideSteps"
                    position="bottom"
                    arrow="center"
                    @close="guideClose"
                    @next="guideNext"
                    @back="guideBack"
                  >
                  </TourTooltip>
                </div>

                <div
                  class="supplier-content__data-label"
                  :class="{ supplier__nodata: !supplier.lastingMethods.length }"
                >
                  Lasting Method
                </div>
                <div class="supplier-content__data-content supplier__grid">
                  <div
                    class="supplier__grid-item supplier__grid-item--large"
                    v-for="(item, i) in filters.lastingMethods"
                    :key="i"
                  >
                    <label class="supplier__checkbox">
                      <input type="checkbox" :value="item" v-model="supplier.lastingMethods" />
                      <span>{{ item.title }}</span>
                    </label>
                  </div>
                </div>

                <div class="supplier-content__data-label" :class="{ supplier__nodata: !supplier.niche.length }">
                  Niche
                </div>
                <div class="supplier-content__data-content supplier__grid">
                  <div
                    class="supplier__grid-item supplier__grid-item--large"
                    v-for="(item, i) in filters.niche"
                    :key="i"
                  >
                    <label class="supplier__checkbox">
                      <input type="checkbox" :value="item" v-model="supplier.niche" />
                      <span>{{ item.title }}</span>
                    </label>
                  </div>
                </div>

                <div
                  class="guide-item"
                  ref="guideItem7"
                  :class="{ 'guide-item--active': guide && supplier.category != null && currentGuideStep == 7 }"
                >
                  <div class="supplier-content__data" :class="{ 'guide-item__content': guide }">
                    <div
                      class="supplier-content__data-label"
                      :class="{ supplier__nodata: !supplier.capabilities.length }"
                    >
                      Capabilities
                    </div>
                    <div
                      v-if="supplier.capabilities"
                      class="supplier-content__data-content supplier__grid supplier__grid"
                    >
                      <div
                        v-if="i == 0 || item.category != filters.capabilities[i - 1].category"
                        class="supplier__grid-group"
                        v-for="(item, i) in filters.capabilities"
                        :key="i"
                      >
                        <div class="supplier__grid-group-label">{{ item.category }}</div>
                        <div class="supplier__grid-group-list">
                          <div
                            class="supplier__grid-item supplier__grid-item--large"
                            v-for="(item, i) in filters.capabilities.filter((i) => i.category == item.category)"
                            :key="i"
                          >
                            <label class="supplier__checkbox">
                              <input type="checkbox" :value="item" v-model="supplier.capabilities" />
                              <span>{{ item.title }}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <TourTooltip
                    v-if="guide && currentGuideStep == 7"
                    title="Specify Which Capabilities You Support With"
                    message="Select in which fields a potential buyer can expect to get support in from you."
                    :currentStep="currentStep"
                    :guideSteps="guideSteps"
                    position="top"
                    arrow="center"
                    @close="guideClose"
                    @next="guideNext"
                    @back="guideBack"
                  >
                  </TourTooltip>
                </div>
              </div>

              <div v-if="supplier.category == 1" class="supplier-content__data">
                <div
                  class="supplier-content__data-label"
                  :class="{
                    supplier__nodata: !(
                      supplier.materialLeather ||
                      supplier.materialTextile ||
                      supplier.materialSynthetic ||
                      supplier.trimsMetal ||
                      supplier.trimsShoelaces ||
                      supplier.trimsToePuffsStiffners ||
                      supplier.trimsInsoles ||
                      supplier.solesHeelsAndRelatedMidsoles ||
                      supplier.solesHeelsAndRelatedOutsoles ||
                      supplier.solesHeelsAndRelatedHeels ||
                      supplier.solesHeelsAndRelatedWelts ||
                      supplier.lastsAndRelatedLasts ||
                      supplier.lastsAndRelatedShanks ||
                      supplier.logisticStickers ||
                      supplier.logisticPackaging
                    ),
                  }"
                >
                  Type of production
                </div>
                <div class="supplier-content__data-content">
                  <div class="cstop" v-for="(item, i) in $store.suppliers.typeOfProduction" :key="i">
                    <div class="cstop__label">{{ item.title }}</div>
                    <div class="cstop__item" v-for="(category, i) in item.options" :key="i">
                      <label class="supplier__checkbox">
                        <input
                          type="checkbox"
                          v-model="supplier[category.key]"
                          @change="cstopHandler"
                          :data-key="category.key"
                        />
                        <span>{{ category.title }}</span>
                      </label>
                      <div v-if="supplier[category.key]" class="cstop__grid">
                        <div v-for="(group, i) in category.options" :key="i">
                          <div class="cstop__label">{{ group.groupTitle }}</div>
                          <div class="supplier__grid-group-list">
                            <div
                              :class="`supplier__grid-item supplier__grid-item--${group.gridSize}`"
                              v-for="(option, i) in group.options"
                              :key="i"
                            >
                              <label class="supplier__checkbox">
                                <input
                                  type="checkbox"
                                  v-model="supplier[option.key]"
                                  :data-group="category.key"
                                  :data-key="option.key"
                                />
                                <span>{{ option.title }}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="guide-item"
                ref="guideItem8"
                :class="{ 'guide-item--active': guide && supplier.category != null && currentGuideStep == 8 }"
              >
                <div :class="{ 'guide-item__content': guide }">
                  <h2
                    id="info"
                    :class="{
                      supplier__nodata:
                        !supplier.founded &&
                        !supplier.employees &&
                        !supplier.moq &&
                        !supplier.dailyProduction &&
                        !supplier.materialExpertiseTextile &&
                        !supplier.materialExpertiseLeather &&
                        !supplier.materialExpertiseSynthetic &&
                        !supplier.sizeRangeFrom &&
                        !supplier.sizeRangeTo,
                    }"
                  >
                    Company information
                  </h2>
                  <div class="supplier-content__data">
                    <div class="supplier-content__data-label" :class="{ supplier__nodata: !supplier.founded }">
                      Founded
                    </div>
                    <div class="supplier-content__data-content" :class="{ supplier__nodata: !supplier.founded }">
                      <input
                        type="text"
                        class="supplier__input"
                        v-model.trim="supplier.founded"
                        placeholder="Click here to add information"
                      />
                    </div>

                    <div class="supplier-content__data-label" :class="{ supplier__nodata: !supplier.employees }">
                      Employees
                    </div>
                    <div class="supplier-content__data-content" :class="{ supplier__nodata: !supplier.employees }">
                      <input
                        type="text"
                        class="supplier__input"
                        v-model.trim="supplier.employees"
                        placeholder="Click here to add information"
                      />
                    </div>
                  </div>
                  <div v-if="supplier.category == 0" class="supplier-content__data">
                    <div class="supplier-content__data-label" :class="{ supplier__nodata: !supplier.moq }">
                      Minimum Order Quantity
                    </div>
                    <div
                      class="supplier-content__data-content supplier__slider"
                      :class="{ supplier__nodata: !supplier.moq }"
                    >
                      <div v-if="supplier.moq" class="supplier__slider-label">
                        <span>{{ supplier.moq == 2000 ? `2000+` : supplier.moq }}</span>
                      </div>
                      <em v-else class="supplier__slider-label" @click="setMOQ"> Select Minimum Order Quantity </em>
                      <vue-slider
                        class="supplier__moq-slider"
                        :value="supplier.moq || 1"
                        :piecewise="true"
                        :data="[1, 20, 50, 100, 150, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1500, 2000]"
                        :width="'100%'"
                        :tooltip="false"
                        :bgStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.13)' }"
                        :sliderStyle="{ backgroundColor: '#fff', border: '2px solid #37495e' }"
                        :processStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.8)' }"
                        @callback="watchMOQ"
                      >
                      </vue-slider>
                    </div>

                    <div
                      class="supplier-content__data-label"
                      :class="{ supplier__nodata: !supplier.dailyProductionFrom || !supplier.dailyProductionTo }"
                    >
                      Daily Production (Pairs)
                    </div>
                    <div
                      class="supplier-content__data-content supplier__slider"
                      :class="{ supplier__nodata: !supplier.dailyProductionFrom || !supplier.dailyProductionTo }"
                    >
                      <div
                        v-if="supplier.dailyProductionFrom || supplier.dailyProductionTo"
                        class="supplier__slider-label"
                      >
                        <span>{{ supplier.dailyProductionFrom }} - {{ supplier.dailyProductionTo }}</span>
                      </div>
                      <em v-else class="supplier__slider-label" @click="setDP"> Select Daily Production </em>
                      <vue-slider
                        class="supplier__moq-slider"
                        :value="[supplier.dailyProductionFrom || 1, supplier.dailyProductionTo || 2000]"
                        :piecewise="true"
                        :data="[
                          1, 20, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 800, 900, 1000,
                          1100, 1200, 1500, 2000,
                        ]"
                        :width="'100%'"
                        :tooltip="false"
                        :bgStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.13)' }"
                        :sliderStyle="{ backgroundColor: '#fff', border: '2px solid #37495e' }"
                        :processStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.8)' }"
                        @callback="watchDP"
                      >
                      </vue-slider>
                    </div>

                    <div class="supplier-content__data-label" :class="{ supplier__nodata: !supplier.brands }">
                      Brands producing
                    </div>
                    <div class="supplier-content__data-content" :class="{ supplier__nodata: !supplier.brands }">
                      <input
                        type="text"
                        class="supplier__input"
                        v-model.trim="supplier.brands"
                        placeholder="Click here to add information"
                      />
                    </div>

                    <div
                      class="supplier-content__data-label"
                      :class="{
                        supplier__nodata:
                          !supplier.materialExpertiseLeather &&
                          !supplier.materialExpertiseTextile &&
                          !supplier.materialExpertiseSynthetic,
                      }"
                    >
                      Material Expertise
                    </div>
                    <div class="supplier-content__data-content supplier__grid">
                      <div class="supplier__grid-item supplier__grid-item--small">
                        <label class="supplier__checkbox">
                          <input type="checkbox" v-model="supplier.materialExpertiseLeather" />
                          <span>Leather</span>
                        </label>
                      </div>
                      <div class="supplier__grid-item supplier__grid-item--small">
                        <label class="supplier__checkbox">
                          <input type="checkbox" v-model="supplier.materialExpertiseTextile" />
                          <span>Textile</span>
                        </label>
                      </div>
                      <div class="supplier__grid-item supplier__grid-item--small">
                        <label class="supplier__checkbox">
                          <input type="checkbox" v-model="supplier.materialExpertiseSynthetic" />
                          <span>Synthetic</span>
                        </label>
                      </div>
                    </div>

                    <div
                      class="supplier-content__data-label"
                      :class="{ supplier__nodata: !supplier.sizeRangeFrom || !supplier.sizeRangeTo }"
                    >
                      Size Range
                    </div>
                    <div
                      class="supplier-content__data-content supplier__slider"
                      :class="{ supplier__nodata: !supplier.sizeRangeFrom || !supplier.sizeRangeTo }"
                    >
                      <div v-if="supplier.sizeRangeFrom || supplier.sizeRangeTo" class="supplier__slider-label">
                        <span>{{ supplier.sizeRangeFrom }} - {{ supplier.sizeRangeTo }}</span>
                      </div>
                      <em v-else class="supplier__slider-label" @click="setSizeRange"> Select Size Range </em>
                      <vue-slider
                        class="supplier__slider-slider"
                        :value="[supplier.sizeRangeFrom || 11, supplier.sizeRangeTo || 55]"
                        :min="11"
                        :max="55"
                        :width="'100%'"
                        :tooltip="false"
                        :bgStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.13)' }"
                        :sliderStyle="{ backgroundColor: '#fff', border: '2px solid #37495e' }"
                        :processStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.8)' }"
                        @callback="watchSizeRange"
                      >
                      </vue-slider>
                    </div>
                  </div>
                </div>
                <TourTooltip
                  v-if="guide && supplier.category != null && currentGuideStep == 8"
                  title="Add Information About Company"
                  message="This is an opportunity to specify what kind of business you are looking for but also to promote yourself."
                  :currentStep="currentStep"
                  :guideSteps="guideSteps"
                  position="top"
                  arrow="left"
                  @close="guideClose"
                  @next="guideNext"
                  @back="guideBack"
                >
                </TourTooltip>
              </div>

              <h2 id="customers">
                {{ supplier.category == 0 ? 'Component suppliers working with' : 'Footwear suppliers working with' }}
              </h2>
              <div class="supplier-content__data">
                <div class="customers-search">
                  <label class="customers-search__form">
                    <i class="customers-search__icon" v-html="svg.searchIcon"></i>
                    <input
                      type="text"
                      class="customers-search__input supplier__input"
                      ref="customerAutocomplete"
                      v-model.trim="searchCustomer"
                      placeholder="Search for suppliers"
                      autocomplete="off"
                      @focus="initCustomersAutocomplete"
                      @blur="destroyCustomersAutocomplete"
                      @keydown="customersHandleKeydown"
                      @input="searchCustomers(supplier.category == 0 ? 1 : 0)"
                    />
                  </label>
                  <div
                    v-if="customersAutcompleteVisible && searchCustomer"
                    class="customers-search__results pac-container"
                  >
                    <div
                      class="pac-item"
                      v-for="(customer, i) in customersSearchResults"
                      :key="`customerSearch${i}`"
                      :class="{ 'pac-item-selected': i === selectedCustomerIndex }"
                      @click.stop="selectCustomer(customer)"
                    >
                      <span class="pac-item-query">
                        {{ customer.name }}
                      </span>
                    </div>
                    <div
                      class="pac-item"
                      :class="{ 'pac-item-selected': selectedCustomerIndex == customersSearchResults.length }"
                      @click="openCustomerModal"
                    >
                      <span class="pac-item-query customers-search__add">
                        <i class="customers-search__add-icon"></i>
                        {{ supplier.category == 0 ? 'Add component supplier' : 'Add footwear supplier' }}
                      </span>
                    </div>
                  </div>
                </div>
                <ul v-if="customers" class="supplier__customers">
                  <li class="supplier__customer" v-for="(customer, i) in customers" :key="i">
                    <div
                      class="supplier__customer-img"
                      :style="[
                        customer.coverPhoto
                          ? {
                              backgroundImage: `url(&quot;${imagePath(
                                'images/' + customer.coverPhoto,
                                1920,
                                500
                              )}&quot;)`,
                            }
                          : { background: '#f9f9f9' },
                      ]"
                    ></div>
                    <div class="supplier__customer-info">
                      <strong class="supplier__customer-title">
                        {{ customer.name }}
                      </strong>
                    </div>
                    <button class="supplier-delete" @click="removeCustomer(i)" title="Remove item"></button>
                  </li>
                </ul>
              </div>

              <div
                class="guide-item"
                ref="guideItem10"
                :class="{ 'guide-item--active': guide && supplier.category != null && currentGuideStep == 10 }"
              >
                <div :class="{ 'guide-item__content': guide }">
                  <h2 id="gallery" :class="{ supplier__nodata: !supplier.gallery || !supplier.gallery.length }">
                    Gallery
                  </h2>
                  <section class="supplier__gallery">
                    <div v-for="(item, i) in supplier.gallery" class="supplier__gallery-item" :key="i">
                      <img
                        :src="`${$blobUrl}/images/${item.url}`"
                        class="supplier__gallery-img"
                        v-if="item.type == 'img'"
                        alt=""
                      />
                      <div v-if="item.type == 'video'" class="supplier__gallery-video supplier__gallery-video">
                        <iframe :src="item.url" frameborder="0"></iframe>
                      </div>
                      <ContentEditable
                        class="supplier__gallery-caption"
                        :content="item.caption"
                        @update="item.caption = $event"
                        :data-placeholder="`Type caption for ${item.type == 'img' ? 'image' : 'video'} (optional)`"
                      >
                      </ContentEditable>
                      <button
                        class="supplier-delete"
                        @click="supplier.gallery.splice(i, 1)"
                        title="Remove item"
                      ></button>
                    </div>
                    <footer class="supplier__gallery-footer">
                      <Progress v-if="galleryImageLoading" :percentage="galleryImageLoadingPercentage"> </Progress>
                      <div v-if="!addVideoForm" class="supplier__gallery-buttons">
                        <label class="supplier__gallery-button">
                          <input
                            type="file"
                            name="galleryImage"
                            accept=".jpg, .png"
                            id="newGalleryImage"
                            @change="readURL($event.target)"
                          />
                          <i v-html="svg.imgIcon"></i>
                          Add image
                        </label>
                        <button class="supplier__gallery-button" @click="openAddVideoForm()">
                          <i v-html="svg.videoIcon"></i>
                          Add video
                        </button>
                      </div>
                      <form
                        v-if="addVideoForm"
                        action="#"
                        method="post"
                        @submit.prevent="addVideo"
                        class="supplier__add-video"
                      >
                        <div class="supplier__add-video-wrap">
                          <input
                            ref="addVideoInput"
                            type="text"
                            placeholder="Paste a YouTube or Vimeo link"
                            class="supplier__add-video-input"
                            v-model.trim="addVideoUrl"
                          />
                          <button type="submit" class="supplier__add-video-button">Add Video</button>
                          <button @click="closeAddVideoForm()" class="supplier__add-video-button">Cancel</button>
                        </div>
                        <span v-if="addVideoUrlError" class="supplier__add-video-error">Video link is not valid</span>
                      </form>
                    </footer>
                  </section>
                </div>
                <TourTooltip
                  v-if="guide && supplier.category != null && currentGuideStep == 10"
                  title="Add Images And Videos"
                  message="Share what kind of products you manage but also the production facilities. Buyers want to see the conditions the products will be produced in. Why not a picture of the team taking care of customer relations to give a face to relate to."
                  :currentStep="currentStep"
                  :guideSteps="guideSteps"
                  position="top"
                  arrow="left"
                  @close="guideClose"
                  @next="guideNext"
                  @back="guideBack"
                  @save="guideSave"
                >
                </TourTooltip>
              </div>
            </div>
            <button class="supplier-user-button" @click.prevent="updateSupplier()">Update Supplier</button>

            <section
              class="location-modal"
              :class="{ active: locationModal, hidden: !locationMap && !$store.user.chineese }"
            >
              <div class="location-modal__overlay" @click="closeLocationModal"></div>
              <button class="location-modal__close" @click="closeLocationModal"></button>
              <div class="location-modal__box" @click.stop>
                <div v-if="!$store.user.chineese" class="location-modal__map" ref="locationModalMap"></div>
                <baidu-map
                  v-if="$store.user.chineese && locationModal"
                  class="location-modal__map"
                  ref="locationModalMap"
                  :scroll-wheel-zoom="baidu.scrollWheelZoom"
                  :center="baiduCenter"
                  :zoom="baidu.locationModal.zoom"
                  :ak="baidu.ak"
                  :mapStyle="baidu.mapStyle"
                  @click="locationModalMapClick"
                >
                  <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                  <bm-marker
                    v-if="baidu.locationModal.coordinates"
                    :position="baidu.locationModal.coordinates"
                    :icon="baidu.markerStyle"
                  >
                  </bm-marker>
                </baidu-map>
                <div v-if="locationNewPlace" class="location-modal__controls">
                  <button class="location-modal__button location-modal__button--white" @click="closeLocationModal">
                    Cancel
                  </button>
                  <button class="location-modal__button location-modal__button--blue" @click="saveNewLocationPlace">
                    Save
                  </button>
                </div>
                <div class="location-modal__tooltip" v-else>Click on the map to set new location</div>
              </div>
            </section>

            <section v-if="customerModal" class="form-popup">
              <div class="form-popup__container">
                <div class="form-popup__content">
                  <button @click="closeCustomerModal" class="form-popup__close"></button>
                  <div class="old-form">
                    <h2 class="old-form__heading">
                      {{ supplier.category == 0 ? 'Add Component Supplier' : 'Add Footwear Supplier' }}
                    </h2>
                    <form @submit.prevent="addCustomer(supplier.category == 0 ? 1 : 0)">
                      <!-- Company Name -->
                      <div class="old-form__control">
                        <label class="old-form__label" :class="{ 'old-form__label--filled': customer.title.length }">
                          <input
                            class="old-form__input"
                            type="text"
                            placeholder="Company Name"
                            v-model.trim="customer.title"
                            required
                          />
                          <span class="old-form__placeholder">Company Name</span>
                        </label>
                        <!-- Company Name validation errors -->
                        <span v-if="customer.titleError" class="old-form__error"
                          >Company with this name already exists</span
                        >
                      </div>
                      <!-- Company Email -->
                      <div class="old-form__control">
                        <label class="old-form__label" :class="{ 'old-form__label--filled': customer.email }">
                          <input
                            class="old-form__input"
                            type="email"
                            placeholder="Company Email"
                            v-model.trim="customer.email"
                            required
                          />
                          <span class="old-form__placeholder">Company Email</span>
                        </label>
                        <!-- Company Email validation errors -->
                        <span v-if="customer.emailError" class="old-form__error"
                          >Company with this name already exists</span
                        >
                      </div>
                      <!-- Submit button -->
                      <div class="old-form__control">
                        <button class="old-form__button" type="submit">Add company</button>
                      </div>
                    </form>
                  </div>
                  <div class="loader" :class="{ 'is-visible': addingCustomer }"></div>
                </div>
              </div>
            </section>

            <section class="form-popup croppa-popup" v-show="coverPhotoCrop">
              <div class="form-popup__container" @click.stop>
                <div class="form-popup__content">
                  <div class="croppa-popup__wrap">
                    <croppa
                      v-model="coverPhotoCroppa"
                      accept=".jpg,.jpeg,.png"
                      @new-image-drawn="coverPhotoCrop = true"
                      @file-choose="handleCroppaChange"
                      :width="coverPhotoCropWidth"
                      :height="coverPhotoCropHeight"
                      :quality="coverPhotoCropQuality"
                      :prevent-white-space="true"
                      :show-remove-button="false"
                      placeholder-color="#333"
                      :placeholder-font-size="16"
                      canvas-color="transparent"
                    >
                    </croppa>
                    <span class="croppa-popup__fixed-text"><i>☰</i> Drag to Reposition Cover</span>
                  </div>
                  <button
                    @click="
                      coverPhotoCroppa.remove();
                      coverPhotoCrop = false;
                    "
                    class="form-popup__close"
                  ></button>
                  <div class="croppa-popup__footer">
                    <div class="croppa-popup__footer-left">
                      <button @click="coverPhotoCroppa.zoomIn()" v-html="svg.plusIcon"></button>
                      <button @click="coverPhotoCroppa.zoomOut()" v-html="svg.minusIcon"></button>
                    </div>
                    <div class="croppa-popup__footer-right">
                      <button
                        @click="
                          coverPhotoCroppa.remove();
                          coverPhotoCrop = false;
                        "
                        class="old-form__button old-form__button--inline old-form__button--outlined"
                      >
                        Cancel
                      </button>
                      <button @click="uploadCover('image/jpeg', 0.8)" class="old-form__button old-form__button--inline">
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';

const directives = {};

// Client only
if (!Vue.prototype.$isServer) {
  // Quill Editor
  const VueQuillEditor = require('vue-quill-editor/dist/ssr');
  const { Quill } = require('vue-quill-editor/dist/ssr');
  const Delta = require('quill-delta');

  const Clipboard = Quill.import('modules/clipboard');

  class PlainTextClipboard extends Clipboard {
    onPaste(e) {
      if (e.defaultPrevented || !this.quill.isEnabled()) return;
      let range = this.quill.getSelection();
      let delta = new Delta().retain(range.index);

      if (e && e.clipboardData && e.clipboardData.types && e.clipboardData.getData('text/html')) {
        let text = (e.originalEvent || e).clipboardData.getData('text/html');
        let cleanedText = this.convert(text);

        // Stop the data from actually being pasted
        e.stopPropagation();
        e.preventDefault();

        // Process cleaned text
        delta = delta.concat(cleanedText).delete(range.length);
        this.quill.updateContents(delta, Quill.sources.USER);
        // range.length contributes to delta.length()
        this.quill.setSelection(delta.length() - range.length, Quill.sources.SILENT);

        return false;
      }
    }
  }
  Quill.register('modules/clipboard', PlainTextClipboard);

  directives['quill'] = VueQuillEditor.quillEditor;
}
import { imagePath } from '@lib/imgproxy';

import Croppa from 'vue-croppa';

import coordtransform from 'coordtransform';
import BaiduMap from 'vue-baidu-map/components/map/Map.vue';
import BmNavigation from 'vue-baidu-map/components/controls/Navigation.vue';
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue';

import baiduMapsStyleGetter from '../lib/baiduMapsStyleGetter';

import mapStyle from '../lib/google-maps-style-min.json';
// SVG
import overviewIcon from '../img/home-icon.svg';
import productionIcon from '../img/build-icon.svg';
import companyIcon from '../img/domain-icon.svg';
import galleryIcon from '../img/photo-icon.svg';
import siteIcon from '../img/public-icon.svg';
import emailIcon from '../img/email-icon.svg';
import phoneIcon from '../img/phone-icon.svg';
import whatsApp from '../img/whatsapp-icon.svg';
import weChat from '../img/wechat-icon.svg';
import skype from '../img/skype-icon.svg';
import facebook from '../img/facebook-s-icon.svg';
import linkedIn from '../img/linkedin-s-icon.svg';
import twitter from '../img/twitter-s-icon.svg';
import instagram from '../img/instagram-s-icon.svg';
import pinterest from '../img/pinterest-s-icon.svg';
import googlePlus from '../img/gplus-s-icon.svg';
import youtube from '../img/youtube-s-icon.svg';
import pinMarker from '../img/pin-marker.svg';
import imgIcon from '../img/img-icon.svg';
import videoIcon from '../img/video-icon.svg';
import searchIcon from '../img/search-icon.svg';
import customersIcon from '../img/customers-icon.svg';
import plusIcon from '../img/plus-icon.svg';
import minusIcon from '../img/minus-icon.svg';

import ContentEditable from './content-editable.vue';
import TourTooltip from './guide-tooltip.vue';

import VueSlider from 'vue-slider-component/src/vue2-slider.vue';
import Progress from './progress.vue';

let coordinatesChangeTimeout = null;

const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

function edge() {
  return (
    typeof window !== 'undefined' &&
    window.navigator &&
    window.navigator.userAgent &&
    window.navigator.userAgent.indexOf('Edge') > -1
  );
}

export default {
  load({ store, route }) {
    store.head.title = `FindSourcing – Edit Supplier`;

    return Promise.all([
      store.suppliers.loadById(route.params.id),
      store.suppliers.loadFilters(),
      store.knowledgebank.loadList(),
    ]);
  },
  components: {
    ContentEditable,
    TourTooltip,
    VueSlider,
    Progress,
    BaiduMap,
    BmNavigation,
    BmMarker,
    croppa: Croppa.component,
  },
  directives,
  data() {
    const supplier = this.$store.suppliers.all[this.$route.params.id] || {};

    return {
      supplier,

      selectedFootwearTypes: (supplier.footwearTypes || []).map((article) => article.id),
      selectedProcesses: (supplier.processes || []).map((article) => article.id),

      guide: !!this.$route.query.guide,
      guideIsStarted: false,
      currentGuideStep: 1,
      updatingSupplier: false,

      navigationIsFixed: false,
      titleUniqueError: false,
      coordinatesError: false,

      map: null,
      marker: null,
      locationModal: false,
      locationMap: null,
      locationMarker: null,
      locationNewPlace: null,

      filteredCountries: null,
      searchCountry: '',
      searchCountryTemp: '',
      selectedCountryIndex: null,
      countriesAutcompleteVisible: false,
      countryError: false,

      customersSearchResults: [],
      searchCustomer: '',
      selectedCustomerIndex: null,
      customersAutcompleteVisible: false,
      customerModal: false,
      customer: {
        title: '',
        email: '',
        titleError: false,
        emailError: false,
      },
      addingCustomer: false,

      editorOptions: {
        formats: ['bold', 'italic', 'underline', 'strike', 'list'],
        theme: 'snow',
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['clean'],
            ],
          },
        },
        placeholder:
          'Enter information about your company, what kind of company you want to be and what is important to you',
        clipboard: {
          matchVisual: false,
        },
      },

      coverPhotoAdded: false,
      coverPhotoData: '',
      coverPhotoLoading: false,
      coverPhotoLoadingPercentage: 0,
      coverPhotoCroppa: null,
      coverPhotoCrop: false,
      coverPhotoCropWidth: null,
      coverPhotoCropHeight: 250,
      coverPhotoCropQuality: 2,
      coverPhotoName: null,

      galleryImageLoading: false,
      galleryImageLoadingPercentage: 0,

      addVideoForm: false,
      addVideoUrl: '',
      addVideoUrlError: false,

      showSocialLinksOnMobile: false,

      svg: {
        overviewIcon,
        productionIcon,
        companyIcon,
        galleryIcon,
        siteIcon,
        emailIcon,
        phoneIcon,
        whatsApp,
        weChat,
        skype,
        facebook,
        linkedIn,
        twitter,
        instagram,
        pinterest,
        googlePlus,
        youtube,
        pinMarker,
        imgIcon,
        videoIcon,
        searchIcon,
        customersIcon,
        plusIcon,
        minusIcon,
      },
      validate: false,

      baidu: {
        scrollWheelZoom: false,
        zoom: 14,
        ak: APP_CONFIG.baidu.maps.ak,
        mapStyle: { styleJson: baiduMapsStyleGetter() },
        markerStyle: {
          url: `data:image/svg+xml;base64,${window.btoa(pinMarker)}`,
          size: {
            width: 30,
            height: 30,
          },
          anchor: {
            width: 30,
            height: 30,
          },
        },
        coordinatesAsText: '',
        load: false,
        locationModal: {
          zoom: 5,
          coordinates: null,
        },
      },
    };
  },
  computed: {
    userHasPermission() {
      return this.$store.user.isAdmin || this.$store.user.hasCompany(this.$route.params.id);
    },
    filters() {
      return this.$store.suppliers.filters.footwearSuppliersFilters;
    },
    articlesList() {
      return this.$store.knowledgebank.list;
    },
    coverPhoto() {
      return !!this.supplier.coverPhoto || this.coverPhotoAdded || this.coverPhotoData;
    },
    saveDisabled() {
      return this.coverPhotoLoading;
    },
    footwearTypesArticles() {
      return this.articlesList.filter((a) => a.categorySlug === 'footwear-types');
    },
    processesArticles() {
      return this.articlesList.filter((a) => a.categorySlug === 'construction');
    },
    materialsArticles() {
      return this.articlesList.filter((a) => a.categorySlug === 'materials');
    },
    selectedMaterials() {
      let arr = [];
      this.supplier.materials.forEach((m) => {
        arr.push(m.id);
      });
      return arr;
    },
    selectedLastingMethods() {
      let arr = [];
      this.supplier.lastingMethods.forEach((m) => {
        arr.push(m.id);
      });
      return arr;
    },
    selectedNiches() {
      let arr = [];
      this.supplier.niche.forEach((m) => {
        arr.push(m.id);
      });
      return arr;
    },
    selectedCapabilities() {
      let arr = [];
      this.supplier.capabilities.forEach((m) => {
        arr.push(m.id);
      });
      return arr;
    },
    selectedSuppliers() {
      let arr = [];
      this.supplier.suppliers.forEach((c) => {
        arr.push(c.id);
      });
      return arr;
    },
    selectedCustomers() {
      let arr = [];
      this.supplier.customers.forEach((c) => {
        arr.push(c.id);
      });
      return arr;
    },
    editor() {
      return this.supplierOverview;
    },

    // Customers
    customers() {
      if (this.supplier.category !== null) {
        return this.supplier.category == 0 ? this.supplier.suppliers : this.supplier.customers;
      } else {
        return null;
      }
    },

    // Map
    geocoder() {
      return new google.maps.Geocoder();
    },
    coordinates() {
      if (this.supplier.coordinates && !this.$store.user.chineese) {
        let coords = this.supplier.coordinates.split(',');
        if (coords.length !== 2) return null;
        if (!coords[0] || !coords[1]) return null;
        let lat = +coords[0].trim();
        let lng = +coords[1].trim();
        if (isNaN(lat) || isNaN(lng)) return null;
        return new google.maps.LatLng({
          lat: lat,
          lng: lng,
        });
      } else {
        return null;
      }
    },
    mapOptions() {
      return {
        center: this.coordinates || new google.maps.LatLng('39.399872', '-8.224454'),
        zoom: this.coordinates ? 17 : 7,
        styles: mapStyle,
        disableDefaultUI: true,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP,
        },
      };
    },

    // Baidu map
    baiduGeocoder() {
      return new this.BMap.Geocoder();
    },
    baiduCoordinates() {
      if (this.baidu.coordinatesAsText) {
        let coords = this.baidu.coordinatesAsText.split(',');
        if (coords.length !== 2) return null;
        if (!coords[0] || !coords[1]) return null;
        let lat = +coords[0].trim();
        let lng = +coords[1].trim();
        if (isNaN(lat) || isNaN(lng)) return null;

        return {
          lat,
          lng,
        };
      } else {
        return null;
      }
    },
    baiduCenter() {
      if (this.baiduCoordinates && this.baidu.load) return this.baiduCoordinates;

      return '北京';
    },

    supplierMapRef() {
      if (this.$store.user.chineese) return this.$refs.supplierMap.$el;

      return this.$refs.supplierMap;
    },

    // Errors
    titleError() {
      return !this.supplier.title;
    },
    addressError() {
      return !this.supplier.fullAddress;
    },
    homepageError() {
      return this.supplier.web ? !urlRegex.test(this.supplier.web) : false;
    },
    emailError() {
      const re = /\S+@\S+\.\S+/;
      return !re.test(this.supplier.email);
    },
    phoneError() {
      const re = /^[+]{0,1}[0-9]{0,3}[-\s\.]{0,1}[(]{0,1}[0-9]{3}[)]{0,1}[\s\.]{0,1}[0-9,-\s\.]{7,9}$/;
      return !re.test(this.supplier.phone);
    },
    footwearTypesError() {
      return !this.selectedFootwearTypes.length;
    },
    processError() {
      return !this.selectedProcesses.length;
    },
    genderError() {
      return !this.supplier.men && !this.supplier.ladies && !this.supplier.babies && !this.supplier.kids;
    },
    // Social links
    linkedInError() {
      return this.supplier.linkedIn ? !urlRegex.test(this.supplier.linkedIn) : false;
    },
    facebookError() {
      return this.supplier.facebook ? !urlRegex.test(this.supplier.facebook) : false;
    },
    instagramError() {
      return this.supplier.instagram ? !urlRegex.test(this.supplier.instagram) : false;
    },
    pinterestError() {
      return this.supplier.pinterest ? !urlRegex.test(this.supplier.pinterest) : false;
    },
    twitterError() {
      return this.supplier.twitter ? !urlRegex.test(this.supplier.twitter) : false;
    },
    googlePlusError() {
      return this.supplier.googlePlus ? !urlRegex.test(this.supplier.googlePlus) : false;
    },
    youtubeError() {
      return this.supplier.youTube ? !urlRegex.test(this.supplier.youTube) : false;
    },

    validationError() {
      let error =
        this.titleError ||
        this.titleUniqueError ||
        this.addressError ||
        this.countryError ||
        this.coordinatesError ||
        this.homepageError ||
        this.emailError ||
        this.phoneError ||
        this.linkedInError ||
        this.facebookError ||
        this.instagramError ||
        this.pinterestError ||
        this.twitterError ||
        this.googlePlusError;
      if (this.supplier.category == 0 && !error) {
        error = this.footwearTypesError || this.processError || this.genderError;
      }
      return error;
    },

    guideStepsArray() {
      if (this.supplier.category == 0) {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      }
      if (this.supplier.category == 1) {
        return [1, 2, 3, 8, 9, 10];
      }
    },
    guideSteps() {
      return this.guideStepsArray.length;
    },
    currentStep() {
      return this.guideStepsArray.indexOf(this.currentGuideStep) + 1;
    },

    navigation() {
      return this.$refs['nav'];
    },
  },
  methods: {
    imagePath,
    initNav() {
      this.navigation.classList.remove('supplier__nav-list--fixed');
      this.navigationIsFixed = false;
      this.toggleNav();
    },
    toggleNav() {
      let headerHeight = this.$refs['header'] ? this.$refs['header'].clientHeight : 0;
      let breakPoint = headerHeight + 65;
      if (this.navigationIsFixed && window.pageYOffset <= breakPoint) {
        this.navigationIsFixed = false;
        this.navigation.classList.remove('supplier__nav-list--fixed');
      } else if (!this.navigationIsFixed && window.pageYOffset > breakPoint) {
        this.navigationIsFixed = true;
        this.navigation.classList.add('supplier__nav-list--fixed');
      }
    },
    slug(s) {
      if (!s) return;
      return s
        .trim()
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/\\/g, '')
        .replace(/[^-a-z0-9]/gi, '')
        .replace(/--/g, '-');
    },
    removeCoverPhoto() {
      this.coverPhotoAdded = false;
      this.coverPhotoData = '';
      this.supplier.coverPhoto = '';
    },
    // Map and address
    baiduReady({ BMap, map }) {
      this.map = map;
      this.BMap = BMap;
    },
    baiduLoad() {
      this.baidu.load = true;
    },
    baiduKeydown(event) {
      // Ctrl key pressed
      if (event.keyCode === 17) {
        this.baidu.scrollWheelZoom = true;
      }
    },
    baiduKeyup(event) {
      // Ctrl key pressed
      if (event.keyCode === 17) {
        this.baidu.scrollWheelZoom = false;
      }
    },
    locationModalMapClick({ point }) {
      this.baidu.locationModal.coordinates = point;
      this.locationNewPlace = true;
    },
    updateSupplierCoordinatesFromBaiduCoordinates() {
      let coords = this.baidu.coordinatesAsText.split(',');
      if (coords.length !== 2) return;
      if (!coords[0] || !coords[1]) return;
      let lat = +coords[0].trim();
      let lng = +coords[1].trim();
      if (isNaN(lat) || isNaN(lng)) return;

      [lng, lat] = coordtransform.gcj02towgs84(...coordtransform.bd09togcj02(lng, lat));

      this.supplier.coordinates = lat + ', ' + lng;
    },
    updateBaiduCoordinatesAsText() {
      if (!this.supplier.coordinates) return null;

      let coords = this.supplier.coordinates.split(',');
      if (coords.length !== 2) return null;
      if (!coords[0] || !coords[1]) return null;
      let lat = +coords[0].trim();
      let lng = +coords[1].trim();
      if (isNaN(lat) || isNaN(lng)) return null;

      [lng, lat] = coordtransform.gcj02tobd09(...coordtransform.wgs84togcj02(lng, lat));

      this.baidu.coordinatesAsText = lat + ', ' + lng;
    },
    async initBMaps() {
      // mounted
      this.updateBaiduCoordinatesAsText();
    },
    // Location modal
    async openLocationModalBaidu() {
      // openLocationModal
      this.baidu.locationModal.zoom = this.baidu.zoom;
      if (this.baiduCoordinates) this.baidu.locationModal.coordinates = this.baiduCoordinates;
      this.locationModal = true;
    },
    checkCoordinatesBaidu() {
      // watch -> baidu.coordinatesAsText
      const { BMap } = this;

      if (!BMap) return;

      if (!this.baiduCoordinates) {
        if (this.supplier.country === 'China') this.coordinatesError = true;
        return;
      }

      this.baiduGeocoder.getLocation(new BMap.Point(this.baiduCoordinates.lng, this.baiduCoordinates.lat), (place) => {
        if (!place || !place.point) {
          if (this.supplier.country === 'China') this.coordinatesError = true;
          return;
        }

        this.coordinatesError = false;
      });
    },

    initGMaps() {
      // mounted
      this.initMap();
      this.initAddressAutocomplete();
      this.initCityAutocomplete();
    },
    initMap() {
      // initGMaps
      this.map = new google.maps.Map(this.supplierMapRef, this.mapOptions);
      this.marker = null;

      if (!this.coordinates) return;
      this.initMarker();
    },
    initMarker() {
      // initMap + setMapCenter
      if (this.marker) {
        this.marker.setPosition(this.coordinates);
        return;
      }
      this.marker = new google.maps.Marker({
        position: this.coordinates,
        map: this.map,
        icon: this.markerIcon(),
      });
    },
    markerIcon() {
      // initMarker + initLocationMarker
      let icon = window.btoa(this.svg.pinMarker);
      return {
        url: `data:image/svg+xml;base64,${icon}`,
        scaledSize: new google.maps.Size(30, 30),
        anchor: new google.maps.Point(15, 30),
      };
    },
    setMapCenter() {
      // setNewAddress
      if (!this.coordinates) return;

      this.map.panTo(this.coordinates);
      if (this.marker) {
        this.marker.setPosition(this.coordinates);
      } else {
        this.initMarker();
      }
    },
    setNewAddress(place, trigger) {
      // initAddressAutocomplete + initCityAutocomplete + saveNewLocationPlace + checkCoordinates
      console.log(trigger);
      // Reset values
      this.supplier.city = '';
      // Set new values
      if (place.address_components) {
        for (let i = 0; i < place.address_components.length; i++) {
          // City
          if (place.address_components[i].types[0] == 'locality') {
            this.supplier.city = place.address_components[i].long_name;
          }
          // Country
          if (place.address_components[i].types[0] == 'country') {
            this.supplier.country = place.address_components[i].long_name;
            this.searchCountry = place.address_components[i].long_name;
            this.countryError = false;
          }
        }
      }
      // Coordinates
      if (place.geometry) {
        this.supplier.coordinates = `${(Math.round(place.geometry.location.lat() * 100000000) / 100000000).toFixed(
          8
        )}, ${(Math.round(place.geometry.location.lng() * 100000000) / 100000000).toFixed(8)}`;
      }
      // Address
      if (trigger == 'address' || trigger == 'map' || !this.supplier.fullAddress) {
        this.supplier.fullAddress = place.formatted_address;
      }
      // Set new coordinates on the map
      this.setMapCenter();
    },
    initAddressAutocomplete() {
      // initGMaps
      const autocomplete = new google.maps.places.Autocomplete(this.$refs.addressAutocomplete);
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        this.setNewAddress(place, 'address');
      });
    },
    initCityAutocomplete() {
      // initGMaps
      const autocomplete = new google.maps.places.Autocomplete(this.$refs.cityAutocomplete, {
        language: 'en-GB',
        types: ['(cities)'],
      });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        this.setNewAddress(place, 'city');
      });
    },
    initLocationMap() {
      // openLocationModal
      if (this.locationMap) {
        this.locationMap.setZoom(this.map.getZoom());
        if (this.coordinates) {
          this.locationMap.setCenter(this.coordinates);
        }
        return;
      }
      this.locationMap = new google.maps.Map(this.$refs.locationModalMap, this.mapOptions);
      this.locationMap.setZoom(this.map.getZoom());
      google.maps.event.addListener(this.locationMap, 'click', (e) => {
        // Map and marker position
        this.locationMap.panTo(e.latLng);
        this.locationMarker.setPosition(e.latLng);
        // Get clicked place
        this.geocoder.geocode(
          {
            latLng: e.latLng,
          },
          (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                this.locationNewPlace = results[0];
              }
            }
          }
        );
      });
    },
    initLocationMarker() {
      // openLocationModal
      if (this.locationMarker) return;
      this.locationMarker = new google.maps.Marker({
        position: this.coordinates,
        map: this.locationMap,
        icon: this.markerIcon(),
      });
    },
    async openLocationModal() {
      // Template "here"
      if (this.$store.user.chineese) {
        await this.openLocationModalBaidu();
        return;
      }

      await this.$store.countries.loadGMaps();

      this.locationModal = true;
      setTimeout(() => {
        this.initLocationMap();
        this.initLocationMarker();
      }, 100);
    },
    closeLocationModal() {
      // 3 x Location modal + saveNewLocationPlace
      this.locationModal = false;
      this.locationMap = null;
      this.locationMarker = null;
      this.locationNewPlace = null;
    },
    saveNewLocationPlace() {
      // Location modal
      if (this.$store.user.chineese) {
        const point = this.baidu.locationModal.coordinates;
        this.baidu.coordinatesAsText = `${(Math.round(point.lat * 100000000) / 100000000).toFixed(8)}, ${(
          Math.round(point.lng * 100000000) / 100000000
        ).toFixed(8)}`;
      } else {
        this.setNewAddress(this.locationNewPlace, 'map');
      }

      this.closeLocationModal();
    },
    async checkCoordinates() {
      // watch -> supplier.coordinates
      await this.$store.countries.loadGMaps();

      clearTimeout(coordinatesChangeTimeout);
      if (!this.coordinates) {
        if (this.supplier.country !== 'China') this.coordinatesError = true;
        return;
      }
      this.geocoder.geocode(
        {
          latLng: this.coordinates,
        },
        (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              let place = results[0];
              if (!place.address_components) return;
              if (document.activeElement == this.$refs.coordinates) {
                coordinatesChangeTimeout = setTimeout(() => this.setNewAddress(place, 'coordinates'), 1000);
              }
              this.coordinatesError = false;
            } else {
              if (this.supplier.country !== 'China') this.coordinatesError = true;
            }
          } else {
            if (this.supplier.country !== 'China') this.coordinatesError = true;
          }
        }
      );
    },

    // No maps usage after this point

    scrollToError() {
      setTimeout(() => {
        let elRef = document.querySelector('[data-error]').dataset.error,
          el = null;
        if (elRef == 'address' || elRef == 'country' || elRef == 'coordinates') {
          el = this.supplierMapRef;
        } else {
          el = document.querySelector(`[data-field=${elRef}]`);
        }

        this.$scrollTo(el, 0, { offset: -document.querySelector('.header').clientHeight - 20 });
      }, 200);
    },
    async addCustomer(category) {
      this.addingCustomer = true;

      const { status, id } = await this.$store.suppliers.addCustomer(
        {
          title: this.customer.title,
          slug: this.slug(this.customer.title),
          email: this.customer.email,
          category: category,
        },
        [this.supplier.id]
      );

      if (status == 3) {
        this.supplier.customers.push({
          id: id,
          title: this.customer.title,
          category: category,
        });
        this.closeCustomerModal();
        this.$notify({
          type: 'success',
          title: 'New supplier successfully added',
        });
      } else {
        if (status == 1) {
          this.customer.titleError = true;
          this.addingCustomer = false;
        }
        if (status == 2) {
          this.customer.emailError = true;
          this.addingCustomer = false;
        }
      }
    },

    showValidationErrorNotification() {
      this.$notify({
        type: 'warn',
        title: 'Please fill out all required fields',
      });
    },

    async updateSupplier() {
      if (this.updatingSupplier) return;

      if (this.guide) {
        this.supplier.draft = true;
        if (this.titleError || this.titleUniqueError) {
          return;
        }
      } else {
        this.validate = true;
        if (this.validationError) {
          this.scrollToError();
          this.showValidationErrorNotification();
          return;
        }
        this.supplier.draft = false;
      }

      this.updatingSupplier = true;

      if (this.$store.user.chineese) {
        this.updateSupplierCoordinatesFromBaiduCoordinates();
      }

      try {
        let status = await this.$store.suppliers.update({
          supplier: this.supplier,
          footwearTypesIds: this.selectedFootwearTypes,
          processesIds: this.selectedProcesses,
          materialsIds: this.selectedMaterials,
          lastingMethodsIds: this.selectedLastingMethods,
          nicheIds: this.selectedNiches,
          capabilitiesIds: this.selectedCapabilities,
          galleryItems: this.supplier.gallery,
          suppliersIds: this.selectedSuppliers,
          customersIds: this.selectedCustomers,
        });

        if (status == 0) {
          this.titleUniqueError = true;

          if (this.guide) {
            this.currentGuideStep = 1;
          } else {
            this.scrollToError();
            this.showValidationErrorNotification();
          }
        }

        if (status == 1 && !this.guide) {
          this.$notify({
            type: 'success',
            title: 'Supplier successfully updated!',
          });
        }

        if (status == 2 && !this.guide) {
          this.$notify({
            type: 'success',
            title: 'Supplier successfully updated!',
            text: `New email confirmation was sent to ${this.supplier.email}`,
          });
        }
      } finally {
        this.updatingSupplier = false;
      }
    },

    // MOQ
    setMOQ() {
      this.supplier.moq = 1;
    },
    watchMOQ(value) {
      this.supplier.moq = value;
    },

    // Daily Production
    setDP() {
      this.supplier.dailyProductionFrom = 1;
      this.supplier.dailyProductionTo = 2000;
    },
    watchDP(value) {
      this.supplier.dailyProductionFrom = value[0];
      this.supplier.dailyProductionTo = value[1];
    },

    // Size Range
    setSizeRange() {
      (this.supplier.sizeRangeFrom = 11), (this.supplier.sizeRangeTo = 55);
    },
    watchSizeRange(value) {
      (this.supplier.sizeRangeFrom = value[0]), (this.supplier.sizeRangeTo = value[1]);
    },

    readURL(input) {
      const target = input.name;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        const file = input.files[0];
        this.uploadImage(file, target);
      }
    },
    uploadCover(type, compressionRate) {
      this.coverPhotoLoading = true;
      this.coverPhotoData = this.coverPhotoCroppa.generateDataUrl('image/jpeg', 0.1);
      console.log(this.coverPhotoCroppa);

      this.coverPhotoCroppa.generateBlob(
        (blob) => {
          var url = URL.createObjectURL(blob);
          this.uploadImage(blob, 'coverPhoto');
          this.coverPhotoCrop = false;
        },
        type,
        compressionRate
      );
      this.coverPhotoCroppa.remove();
    },
    async uploadImage(file, target) {
      let fileName = '';
      if (target == 'coverPhoto') {
        ({ fileName } = await this.$store.suppliers.uploadCoverPhoto(file, this.coverPhotoName, (percentage) => {
          this.coverPhotoLoadingPercentage = percentage;
        }));
      } else {
        this.galleryImageLoading = true;
        ({ fileName } = await this.$store.suppliers.uploadGalleryImage(file, (percentage) => {
          this.galleryImageLoadingPercentage = percentage;
        }));
      }

      let url =
        target == 'coverPhoto'
          ? `${this.imagePath('images/' + fileName, 1920, 500)}`
          : `${this.$blobUrl}/images/${fileName}`;
      // this is callback data: url
      let img = document.createElement('img');
      img.onload = () => {
        if (target == 'coverPhoto') {
          this.coverPhotoData = url;
          this.supplier.coverPhoto = fileName;
          this.coverPhotoLoading = false;
          this.coverPhotoLoadingPercentage = 0;
        }
        if (target == 'galleryImage') {
          this.addNewGalleryItem(fileName, 'img');
        }
      };
      img.src = url;
    },
    onEditorReady(editor) {
      document.querySelector('.ql-editor').classList.add('supplier-content');
    },
    openAddVideoForm() {
      this.addVideoForm = true;
      this.addVideoUrl = '';
      this.addVideoUrlError = false;
      setTimeout(() => {
        this.$refs.addVideoInput.focus();
      }, 50);
    },
    closeAddVideoForm() {
      this.addVideoForm = false;
    },
    addVideo() {
      this.addVideoUrlError = false;
      let url = this.addVideoUrl;
      url.match(
        /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
      );
      let type;
      if (RegExp.$3.indexOf('youtu') > -1) {
        type = 'youtube';
      } else if (RegExp.$3.indexOf('vimeo') > -1) {
        type = 'vimeo';
      }

      let newVideoUrl,
        videoId = RegExp.$6;
      if (type == 'youtube') {
        newVideoUrl = '//www.youtube.com/embed/' + videoId;
      } else if (type == 'vimeo') {
        newVideoUrl = '//player.vimeo.com/video/' + videoId;
      }
      if (!newVideoUrl) {
        this.addVideoUrlError = true;
        return;
      }
      this.addVideoForm = false;
      this.addNewGalleryItem(newVideoUrl, 'video');
    },
    addNewGalleryItem(url, type) {
      this.supplier.gallery.push({
        type: type,
        url: url,
        caption: '',
        order: 0,
      });
      this.galleryImageLoading = false;
      this.galleryImageLoadingPercentage = 0;
    },

    toggleGuide() {
      if (this.guide) {
        document.body.classList.add('guide');
        if (this.supplier && this.supplier.category !== null) this.guideGoTo(1);
      } else {
        document.body.classList.remove('guide');
      }
    },
    guideGoTo(step) {
      let el = step === 2 ? this.supplierMapRef : this.$refs[`guideItem${this.currentGuideStep}`];
      // Scroll to item if it's not fully visible on screen
      setTimeout(() => {
        try {
          let viewportOffset = el.getBoundingClientRect();
          let itemHeight,
            offset = -30,
            extraOffset = 0,
            tooltipHeight = document.querySelector('.guide-tooltip').clientHeight;
          if (step == 2) {
            itemHeight =
              el.clientHeight + document.querySelector('.supplier__contacts-address').clientHeight + tooltipHeight;
          } else if (step === 7 || step === 8 || step === 10) {
            itemHeight = el.clientHeight;
            extraOffset = tooltipHeight;
          } else if (step === 9) {
            offset = -document.querySelector('.header').clientHeight;
          } else {
            itemHeight = el.clientHeight + tooltipHeight + 20;
          }
          if (viewportOffset.top + itemHeight > window.innerHeight || viewportOffset.top < 0) {
            this.$scrollTo(el, 0, { offset: offset - extraOffset });
          }
        } catch (e) {
          // Do nothing if any error happens here. Among errors that can happen is https://sentry.io/organizations/find-sourcing-ab/issues/1174010211/?project=1518676&referrer=alert_email
          // It is unclear how this can happen. But here are a lot of setTimeouts
          // and direct use of DOM, etc. and the code for the guide seems to be, erhm,
          // a little bit messy. We should probably clean this up at some point instead
          // of trying to deal with these scrolling issues (and do so if/when we get a
          // user complaining or we do larger changes to the supplier edit page).
        }
      }, 200);
    },
    guideNext() {
      this.guideIsStarted = true;
      if (this.titleError) {
        this.currentGuideStep = 1;
        this.scrollToError();
      } else {
        this.currentGuideStep = this.guideStepsArray[this.guideStepsArray.indexOf(this.currentGuideStep) + 1];
        this.updateSupplier();
      }
    },
    guideBack() {
      this.currentGuideStep = this.guideStepsArray[this.guideStepsArray.indexOf(this.currentGuideStep) - 1];
      this.updateSupplier();
    },
    guideSave() {
      this.guide = false;
      this.updateSupplier();
    },
    guideClose() {
      this.guide = false;
    },

    // Countries Autocomplete
    filterCountries(e) {
      // Set temporary value
      if (e && e.target) this.searchCountryTemp = e.target.value.trim() || '';
      // Return null if search query is empty
      if (!this.searchCountry) {
        this.filteredCountries = null;
        return;
      }

      // Filter countries by query
      let results = this.$store.countries.all
        .filter((c) => {
          return c.toLowerCase().indexOf(this.searchCountry.toLowerCase()) === 0;
        })
        .slice(0, 5);

      // Return null if there is only one result and it is the same as current country
      if (results.length == 1 && results[0] == this.searchCountry) {
        this.filteredCountries = null;
        return;
      }

      this.filteredCountries = results;
    },
    initCountriesAutocomplete() {
      this.selectedCountryIndex = null;
      this.countriesAutcompleteVisible = true;
    },
    destroyCountriesAutocomplete() {
      setTimeout(() => {
        this.countriesAutcompleteVisible = false;
        if (!this.searchCountry) {
          this.supplier.country = '';
          this.countryError = true;
        } else if (this.searchCountry != this.supplier.country) {
          if (this.$store.countries.all.indexOf(this.searchCountry) != -1) {
            this.supplier.country = this.searchCountry;
          } else {
            this.searchCountry = this.supplier.country;
          }
          this.countryError = false;
        } else {
          this.supplier.country = this.searchCountry;
          this.countryError = false;
        }
        this.countriesAutcompleteVisible = false;
        this.selectedCountryIndex = null;
        this.filterCountries();
      }, 100);
    },
    countriesHandleKeydown(e) {
      const { keyCode } = e;
      if (!this.filteredCountries) return;

      // ArrowDown
      if (keyCode === 40) {
        e.preventDefault();
        if (this.selectedCountryIndex === this.filteredCountries.length - 1) {
          this.selectedCountryIndex = null;
          this.searchCountry = this.searchCountryTemp;
          return;
        } else if (this.selectedCountryIndex === null) {
          this.selectedCountryIndex = 0;
        } else {
          this.selectedCountryIndex += 1;
        }
        this.searchCountry = this.filteredCountries[this.selectedCountryIndex];
      }

      // ArrowUp
      if (keyCode === 38) {
        e.preventDefault();
        if (this.selectedCountryIndex === null) {
          this.selectedCountryIndex = this.filteredCountries.length - 1;
        } else if (this.selectedCountryIndex === 0) {
          this.selectedCountryIndex = null;
          this.searchCountry = this.searchCountryTemp;
          return;
        } else {
          this.selectedCountryIndex -= 1;
        }
        this.searchCountry = this.filteredCountries[this.selectedCountryIndex];
      }

      // Enter
      if (keyCode === 13) {
        if (this.selectedCountryIndex !== null) {
          this.supplier.country = this.filteredCountries[this.selectedCountryIndex];
          this.searchCountry = this.filteredCountries[this.selectedCountryIndex];
          this.countriesAutcompleteVisible = false;
          this.selectedCountryIndex = null;
          this.countryError = false;
          this.filterCountries();
        }
      }

      // Backspace
      if (keyCode === 8) {
        if (!this.countriesAutcompleteVisible) {
          this.countriesAutcompleteVisible = true;
        }
      }
    },
    selectCountry(country) {
      this.searchCountry = country;
      this.supplier.country = country;
    },

    // Customers autocomplete
    initCustomersAutocomplete() {
      this.selectedCustomerIndex = null;
      this.customersAutcompleteVisible = true;
    },
    destroyCustomersAutocomplete() {
      setTimeout(() => {
        this.selectedCustomerIndex = null;
        this.customersAutcompleteVisible = false;
        this.searchCustomer = '';
        this.customersSearchResults = [];
      }, 100);
    },
    customersHandleKeydown(e) {
      const { keyCode } = e;
      // ArrowDown
      if (keyCode === 40) {
        e.preventDefault();
        if (this.selectedCustomerIndex === this.customersSearchResults.length || this.selectedCustomerIndex === null) {
          this.selectedCustomerIndex = 0;
        } else {
          this.selectedCustomerIndex += 1;
        }
      }
      // ArrowUp
      if (keyCode === 38) {
        e.preventDefault();
        if (this.selectedCustomerIndex === null || this.selectedCustomerIndex === 0) {
          this.selectedCustomerIndex = this.customersSearchResults.length;
        } else {
          this.selectedCustomerIndex -= 1;
        }
      }
      // Enter
      if (keyCode === 13) {
        if (this.selectedCustomerIndex !== null && this.selectedCustomerIndex !== this.customersSearchResults.length) {
          this.selectCustomer(this.customersSearchResults[this.selectedCustomerIndex]);
        }
        if (this.selectedCustomerIndex == this.customersSearchResults.length) {
          this.openCustomerModal();
        }
      }
    },
    async searchCustomers(category) {
      const { suppliers } = await this.$store.suppliers.search(this.searchCustomer, category);

      this.selectedCustomerIndex = null;
      this.customersSearchResults = suppliers.slice(0, 5);
    },
    selectCustomer(customer) {
      this.$refs.customerAutocomplete.blur();
      if (this.supplier.category == 0) {
        if (this.supplier.suppliers.some((s) => s.id == customer.id)) return;
        this.supplier.suppliers.push(customer);
      } else {
        if (this.supplier.customers.some((s) => s.id == customer.id)) return;
        this.supplier.customers.push(customer);
      }
    },
    removeCustomer(index) {
      if (this.supplier.category == 0) {
        this.supplier.suppliers.splice(index, 1);
      } else {
        this.supplier.customers.splice(index, 1);
      }
    },
    openCustomerModal() {
      this.customerModal = true;
      this.customer.title = this.searchCustomer;
      this.$refs.customerAutocomplete.blur();
    },
    closeCustomerModal() {
      this.customerModal = false;
      this.addingCustomer = false;
      this.customer = {
        title: '',
        email: '',
        titleError: false,
        emailError: false,
      };
    },

    cstopHandler(e) {
      if (!e.target.checked) {
        let checkboxes = document.querySelectorAll(`[data-group='${e.target.dataset.key}']`);
        checkboxes.forEach((c) => {
          if (c.checked) {
            this.supplier[c.dataset.key] = false;
          }
        });
      }
    },

    initCoverCrop() {
      if (window.innerWidth > 1950) {
        this.coverPhotoCropWidth = 1920;
        this.coverPhotoCropHeight = 500;
        this.coverPhotoCropQuality = 1;
      } else {
        this.coverPhotoCropWidth = 1920 * (window.innerWidth / 1920 - 0.05);
        this.coverPhotoCropHeight = 500 * (window.innerWidth / 1920 - 0.05);
        this.coverPhotoCropQuality = 1920 / window.innerWidth + 0.05;
      }
    },
    handleCroppaChange(file) {
      this.coverPhotoName = file.name;
    },
  },
  watch: {
    'supplier.title': function (title) {
      this.supplier.slug = this.slug(title);
      this.titleUniqueError = false;
    },
    'supplier.coordinates': function () {
      if (!this.$store.user.chineese) this.checkCoordinates();

      if (this.$store.user.chineese) this.updateBaiduCoordinatesAsText();
    },
    'baidu.coordinatesAsText': function () {
      this.checkCoordinatesBaidu();
    },
    'supplier.category': function (category) {
      if (this.guideIsStarted) {
        this.guideGoTo(this.currentGuideStep);
      }
    },
    'customer.title': function (title) {
      this.customer.titleError = false;
    },
    'customer.email': function () {
      this.customer.emailError = false;
    },
    addVideoUrl() {
      this.addVideoUrlError = false;
    },
    loaded(val) {
      if (val) setTimeout(() => this.toggleGuide(), 100);
    },
    guide() {
      this.toggleGuide();
    },
    currentGuideStep(val) {
      this.guideGoTo(val);
    },
  },
  mounted() {
    if (this.supplier.status !== 0 && this.userHasPermission) {
      this.coverPhotoData = this.supplier.coverPhoto
        ? `${this.imagePath('images/' + this.supplier.coverPhoto, 1920, 500)}`
        : '';
      this.searchCountry = this.supplier.country;
      this.editor.history.clear();

      if (this.$store.user.chineese) {
        this.initBMaps();
        document.addEventListener('keydown', this.baiduKeydown);
        document.addEventListener('keyup', this.baiduKeyup);
      } else {
        this.$store.countries.loadGMaps().then(() => {
          this.initGMaps();
        });
      }

      this.initNav();
      this.initCoverCrop();
      window.addEventListener('scroll', this.toggleNav);
      window.addEventListener('resize', this.initNav);
      window.addEventListener('resize', this.initCoverCrop);
    }
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.toggleNav);
    window.removeEventListener('resize', this.initNav);
    window.removeEventListener('resize', this.initCoverCrop);

    if (this.$store.user.chineese) {
      document.removeEventListener('keydown', this.baiduKeydown);
      document.removeEventListener('keyup', this.baiduKeyup);
    }
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';

// Quill
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
// Vue-Croppa
@import 'vue-croppa/dist/vue-croppa.css';

.supplier-content__data-content:empty {
  &:before {
    content: attr(data-placeholder);
    font-style: italic;
  }
}
.supplier__gallery-footer {
  background-image: url('../img/addNewGalleryItemPH.jpg');
}

// Category modal
.supplier-category {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba($c-light, 0.9);
  &__box {
    width: 90%;
    max-width: math.div(50rem, 1.6);
    padding: math.div(1.5rem, 1.6);
    background: $c-light-gray;
  }
  &__title {
    display: block;
    font-size: math.div(2rem, 1.6);
    font-family: 'Montserrat', sans-serif;
    color: #37495e;
    letter-spacing: 0.5px;
    font-weight: normal;
  }
  &__text {
    line-height: 1.6;
    margin: math.div(1rem, 1.6) 0;
    color: rgba($c-dark, 0.7);
  }
  &__buttons {
    display: flex;
  }
  &__button {
    cursor: pointer;
    display: inline-block;
    line-height: math.div(3rem, 1.6);
    padding: 0 math.div(1.5rem, 1.6);
    margin-right: math.div(1.5rem, 1.6);
    background: $c-blue;
    color: $c-light;
    input {
      display: none;
    }
  }
}

// Tour
.guide-item {
  &:not(.supplier__contacts-list) {
    width: 100%;
  }
  &--active {
    position: relative;
    z-index: 200;
    &:before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 200vh;
      background: rgba($c-light, 0.8);
    }
  }
  &__content {
    position: relative;
    background-color: $c-light;
  }
}

// Map modal
.location-modal {
  display: none;
  &.active {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 300;
    width: 100%;
    height: 100vh;
  }
  &.hidden {
    opacity: 0;
  }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($c-dark, 0.9);
  }
  &__close {
    display: block;
    position: absolute;
    top: math.div(3rem, 1.6);
    right: math.div(3rem, 1.6);
    width: math.div(2.7rem, 1.6);
    height: math.div(2.7rem, 1.6);
    padding: 0;
    border: 0;
    cursor: pointer;
    background: transparent;
    outline: none;
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: math.div(1.3rem, 1.6);
      left: 0;
      width: 100%;
      height: 1px;
      background: $c-light;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  &__box {
    position: relative;
    width: calc(100% - #{math.div(3rem, 1.6)});
    max-width: math.div(80rem, 1.6);
    height: 80vh;
    max-height: math.div(60rem, 1.6);
    border: 1px solid rgba($c-light, 0.2);
    background: rgba($c-light, 0.02);
  }
  &__map {
    position: absolute;
    top: math.div(0.5rem, 1.6);
    right: math.div(0.5rem, 1.6);
    bottom: math.div(0.5rem, 1.6);
    left: math.div(0.5rem, 1.6);
  }
  &__controls {
    position: absolute;
    bottom: math.div(2.5rem, 1.6);
    right: math.div(1.5rem, 1.6);
    display: flex;
  }
  &__button {
    height: math.div(3rem, 1.6);
    padding: 0 math.div(1.5rem, 1.6);
    margin-left: math.div(1rem, 1.6);
    border: 0;
    cursor: pointer;
    outline: none;
    &--white {
      color: $c-dark;
      background: $c-light;
    }
    &--blue {
      color: $c-light;
      background: $c-blue;
    }
  }
  &__tooltip {
    position: absolute;
    bottom: math.div(2.5rem, 1.6);
    right: math.div(1.5rem, 1.6);
    padding: 0 math.div(1rem, 1.6);
    line-height: math.div(3rem, 1.6);
    background: rgba($c-light, 0.8);
  }
}

// Customer modal
.customer-modal {
  display: none;
  &.active {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 400;
    width: 100%;
    height: 100vh;
  }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($c-dark, 0.9);
  }
  &__close {
    display: block;
    position: absolute;
    top: math.div(3rem, 1.6);
    right: math.div(3rem, 1.6);
    width: math.div(2.7rem, 1.6);
    height: math.div(2.7rem, 1.6);
    padding: 0;
    border: 0;
    cursor: pointer;
    background: transparent;
    outline: none;
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: math.div(1.3rem, 1.6);
      left: 0;
      width: 100%;
      height: 1px;
      background: $c-light;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  &__box {
    position: relative;
    width: calc(100% - #{math.div(3rem, 1.6)});
    max-width: math.div(40rem, 1.6);
    max-height: math.div(60rem, 1.6);
    padding: math.div(4rem, 1.6) math.div(2rem, 1.6);
    text-align: center;
    background: $c-light;
    color: $c-dark;
    .loader {
      position: absolute;
      height: 100%;
    }
  }
  &__title {
    font-weight: normal;
    color: $c-dark;
    margin: 0;
  }
  &__form {
    max-width: math.div(30rem, 1.6);
    margin: 0 auto;
  }
  &__control {
    margin-top: math.div(2rem, 1.6);
    strong {
      display: block;
      margin-bottom: math.div(0.5rem, 1.6);
      text-align: left;
    }
  }
  &__input {
    width: 100%;
    padding: math.div(1rem, 1.6) math.div(1.5rem, 1.6);
    line-height: math.div(2rem, 1.6);
    font-size: math.div(1.6rem, 1.6);
    border: 1px solid rgba($c-dark, 0.2);
  }
  &__button {
    display: block;
    width: 100%;
    line-height: math.div(4rem, 1.6);
    font-size: math.div(1.6rem, 1.6);
    border: 1px solid $c-blue;
    color: $c-blue;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.5s ease;
    &:hover {
      color: $c-light;
      background: $c-blue;
    }
  }
  &__error {
    display: block;
    text-align: left;
    margin-top: math.div(0.5rem, 1.6);
    font-size: math.div(1.3rem, 1.6);
    color: $c-red;
  }
}

.customers-search {
  width: 100%;
  position: relative;
  margin-top: 0;
  margin-bottom: math.div(2rem, 1.6);
  &__form {
    display: flex;
    align-items: center;
    border: 1px solid #e6e6e6;
  }
  &__icon {
    display: block;
    width: math.div(1.5rem, 1.6);
    margin: 0 0 0 math.div(1rem, 1.6);
    svg {
      width: 100%;
      fill: rgba($c-dark, 0.5);
    }
  }
  &__input {
    padding: math.div(1rem, 1.6);
    margin: 0;
  }
  &__results.pac-container {
    margin-top: -1px;
  }
  &__add {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
    &-icon {
      display: block;
      position: relative;
      width: math.div(1.4rem, 1.6);
      height: math.div(1.4rem, 1.6);
      margin-right: math.div(1rem, 1.6);
      &:before,
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        top: math.div(0.6rem, 1.6);
        left: 0;
        background: $c-dark;
      }
      &:after {
        transform: rotate(90deg);
      }
    }
  }
}

.supplierEdit {
  .ql-toolbar {
    .ql-stroke {
      color: $c-dark;
      stroke: $c-dark;
    }
    .ql-fill {
      color: $c-dark;
      fill: $c-dark;
    }
    button.ql-active,
    button:hover {
      .ql-stroke {
        color: $c-blue !important;
        stroke: $c-blue !important;
      }
      .ql-fill {
        color: $c-blue !important;
        fill: $c-blue !important;
      }
    }
  }
}
.croppa-popup {
  .form-popup__container {
    width: auto;
    max-width: 1920px;
  }
  .form-popup__content {
    padding: 0;
  }
  &__footer {
    padding: math.div(1rem, 1.6);
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-left {
      display: flex;
      & > * {
        margin-right: math.div(1rem, 1.6);
      }
      button {
        display: block;
        padding: 0;
        outline: none;
        background: transparent;
        border: none;
        cursor: pointer;
      }
    }
    &-right {
      display: flex;
      & > * {
        margin-left: math.div(1rem, 1.6);
      }
    }
    svg {
      display: block;
      width: math.div(2.5rem, 1.6);
      height: math.div(2.5rem, 1.6);
      fill: rgba($c-dark, 0.6);
      &:hover {
        fill: rgba($c-dark, 0.8);
      }
    }
  }
  &__wrap {
    position: relative;
  }
  &__fixed-text {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba($c-dark, 0.3);
    color: $c-light-gray;
    line-height: math.div(2rem, 1.6);
    padding: math.div(0.5rem, 1.6) math.div(1rem, 1.6);
    font-size: math.div(1.4rem, 1.6);
    pointer-events: none;
    i {
      font-style: normal;
      display: inline-block;
      margin-right: 2px;
      opacity: 0.5;
    }
  }
}
</style>
