<template>
  <div
    class="guide-tooltip"
    :class="{
      'guide-tooltip--pt': position == 'top',
      'guide-tooltip--pb': position == 'bottom',
      'guide-tooltip--al': arrow == 'left',
      'guide-tooltip--ac': arrow == 'center',
      'guide-tooltip--ar': arrow == 'right',
    }"
  >
    <div class="guide-tooltip__info">
      <strong class="guide-tooltip__heading">
        {{ title }}
      </strong>
      <p class="guide-tooltip__message">
        {{ message }}
      </p>
    </div>
    <button class="guide-tooltip__close" @click="$emit('close')"></button>
    <div class="guide-tooltip__footer">
      <div class="guide-tooltip__counter">{{ currentStep }} / {{ guideSteps }}</div>
      <div class="guide-tooltip__buttons">
        <button
          class="guide-tooltip__button guide-tooltip__button--outline"
          @click="$emit('back')"
          v-if="currentStep > 1"
        >
          Back
        </button>
        <button
          class="guide-tooltip__button guide-tooltip__button--blue"
          @click="$emit('next')"
          v-if="currentStep < guideSteps"
        >
          Next
        </button>
        <button
          class="guide-tooltip__button guide-tooltip__button--blue"
          @click="$emit('save')"
          v-if="currentStep == guideSteps"
        >
          Finish
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'message', 'currentStep', 'guideSteps', 'position', 'arrow'],
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
.guide-tooltip {
  position: absolute;
  width: 100%;
  max-width: math.div(76rem, 1.6);
  left: 50%;
  transform: translateX(-50%);
  padding: math.div(1rem, 1.6) math.div(1.5rem, 1.6);
  background: $c-light-gray;
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }
  &--pt {
    bottom: calc(100% + #{math.div(1.5rem, 1.6)});
    animation: tooltipTop 0.5s ease;
    &:before {
      bottom: math.div(-0.8rem, 1.6);
      border-width: math.div(0.8rem, 1.6) math.div(0.8rem, 1.6) 0 math.div(0.8rem, 1.6);
      border-color: $c-light-gray transparent transparent transparent;
    }
  }
  &--pb {
    top: calc(100% + #{math.div(1.5rem, 1.6)});
    animation: tooltipBottom 0.5s ease;
    &:before {
      top: math.div(-0.8rem, 1.6);
      border-width: 0 math.div(0.8rem, 1.6) math.div(0.8rem, 1.6) math.div(0.8rem, 1.6);
      border-color: transparent transparent $c-light-gray transparent;
    }
  }
  &--al:before {
    left: math.div(1.5rem, 1.6);
  }
  &--ar:before {
    right: math.div(1.5rem, 1.6);
  }
  &--ac:before {
    left: 50%;
    margin-left: math.div(-0.8rem, 1.6);
  }
  &__heading {
    display: block;
    font-size: math.div(1.8rem, 1.6);
    font-family: 'Montserrat', sans-serif;
    color: #37495e;
    letter-spacing: 0.5px;
    font-weight: normal;
  }
  &__info {
    line-height: 1.6;
    h2,
    p {
      margin: 0;
    }
    h2 {
      margin: math.div(0.5rem, 1.6) 0;
      font-size: math.div(1.6rem, 1.6);
      font-weight: normal;
    }
    p {
      margin: math.div(0.5rem, 1.6) 0;
      font-size: math.div(1.4rem, 1.6);
      color: rgba($c-dark, 0.7);
    }
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: math.div(2.3rem, 1.6);
    height: math.div(2.3rem, 1.6);
    border: 0;
    border: 1px solid rgba($c-dark, 0.1);
    border-top-color: transparent;
    border-right-color: transparent;
    background-color: transparent;
    cursor: pointer;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: math.div(1rem, 1.6);
      left: math.div(0.4rem, 1.6);
      width: math.div(1.3rem, 1.6);
      height: 1px;
      background: $c-dark;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: math.div(1rem, 1.6);
    padding-top: math.div(1rem, 1.6);
    border-top: 1px solid rgba($c-dark, 0.1);
    font-size: math.div(1.2rem, 1.6);
    line-height: math.div(2.4rem, 1.6);
  }
  &__counter {
    color: rgba($c-dark, 0.7);
  }
  &__buttons {
    display: flex;
  }
  &__button {
    height: math.div(2.4rem, 1.6);
    padding: 0 math.div(1rem, 1.6);
    margin-left: math.div(1rem, 1.6);
    cursor: pointer;
    &--outline {
      border: 1px solid rgba($c-dark, 0.5);
      background-color: transparent;
      color: $c-dark;
    }
    &--blue {
      border: 0;
      background: $c-blue;
      color: $c-light;
    }
  }
}
@keyframes tooltipTop {
  from {
    opacity: 0;
    transform: translate(-50%, #{math.div(-2rem, 1.6)});
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
@keyframes tooltipBottom {
  from {
    opacity: 0;
    transform: translate(-50%, #{math.div(2rem, 1.6)});
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
</style>
